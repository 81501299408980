import React, { MouseEvent, useContext, useEffect, useState } from 'react';
import { MenuContext } from '../Layout/layout';
// import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import "./left-menu.css";
import { useNavigate } from 'react-router-dom';
import { setResults } from '../../store/slices/gallerySlice';
import { useDispatch } from 'react-redux';

function LeftMenuNavigation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { menu, setMenu } = useContext<any>(MenuContext);
  const [hover, setHover] = useState<boolean>(false);
  const handleMouseMove = (e: MouseEvent<HTMLElement>) => {
    e.currentTarget.classList.toggle("hover");
  };
  let disable: boolean;

  // const handleMouseOut = (e: MouseEvent<HTMLElement>) => {
  //   setHover(false);
  // };

  function Nav({ children }: any) {
    return (
      <div className="nav">
        {children}
      </div>
    )
  };

  function MenuItem({ children }: any) {
    return (
      <div className='menu-item'>
        {children}
      </div>
    )
  }

  function CustomDiv({ children, onClick, customClass, selected, disableSelector }: any) {
    disable = useSelector(disableSelector);

    return (
      <div className={`${selected && `${customClass} selected` || customClass} ${disable && 'disable' || ''}`}
        onMouseOver={handleMouseMove}
        onMouseOut={handleMouseMove}
        onClick={onClick} style={{ cursor: 'pointer' }
        }>
        {children}
      </div >
    );
  }

  function handleClick(item: any) {
    if (window.location.pathname !== '/') {
      dispatch(setResults({ results: [], showMore: false }));
      navigate('/');
    }
    setMenu(menu.map((mi: any) => {
      if (mi === item) {
        mi.selected = true;
      } else {
        mi.selected = false
      }
      return mi;
    }));
  }

  useEffect(() => {
  }, [menu]);

  return (
    <Nav>
      {menu.map((item: any, index: number) => (
        <MenuItem key={index}>
          {/* <Tooltip title={item?.title}> */}
          <CustomDiv onClick={() => handleClick(item)} customClass={item.class} selected={item.selected} disableSelector={item.disableSelector}>
            {item.icon}
          </CustomDiv>
          {/* </Tooltip> */}
        </MenuItem>
      ))}
    </Nav>
  );
}

export default LeftMenuNavigation;