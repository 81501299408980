import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import { chargeTokensReducer, deductPriceReducer, paymentIntentReducer, userInfoReducer } from '../components/Payments/redux/reducers';
import cartReducer from "../components/Payments/redux/slices/cartSlice";
import cardReducer from "../components/Payments/redux/slices/cardSlice";
import galleryReducer from "./slices/gallerySlice";
import updateDataReducer from '../components/Payments/redux/reducers';

const rootReducer = combineReducers({
    gallery: galleryReducer,
    update: updateDataReducer,
    cardData: cardReducer,
    balance: deductPriceReducer,
    paymentIntent: paymentIntentReducer,
    userInfo: userInfoReducer,
    cart: cartReducer,
    charge: chargeTokensReducer
});

export default configureStore({
    reducer: rootReducer
});


