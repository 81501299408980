import { radiometricProcessing, imageFormat, SearchModel, productType, Supplier, OrderData, CartItemStatus, CartItem } from "../components/Shared/Models/search-imagery-result.model";
import { updateGallery, setResults, updateResults } from "../store/slices/gallerySlice";
import TosterSrv from "./toster.service";
import axios from "axios";
import { config } from '../config/config';

class OneAtlasService {
    private discoverEP: string = config.REACT_APP_SATELLITE_SERVICE;
    private static instance: OneAtlasService | null = null;
    private constructor() { }

    static getInstance(): OneAtlasService {
        if (!this.instance) {
            this.instance = new OneAtlasService();
        }
        return this.instance;
    }

    _searchModel: any = null;
    _currentResultsPage = 1;
    _totalResultsPages = 0;
    ITEMS_PER_PAGE = 20;
    // token = useSelector(getToken);

    searchAddress = (searchQuery: string) => {
        const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
            searchQuery
        )}&format=json&addressdetails=1`;

        return fetch(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => (response.json()))
            .catch((error) => {
                console.log('Error occurred during geocoding:', error);
            });
    };

    searchResults = (searchModel: any, token: string, dispatch: any) => {
        searchModel.startPage = this._currentResultsPage;
        searchModel.itemsPerPage = this.ITEMS_PER_PAGE;
        this._searchModel = searchModel;
        try {
            return fetch(`${this.discoverEP}/search`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": `Bearer ${token}`
                },
                body: JSON.stringify(searchModel),
            }).then(res => {
                if (res.ok) {
                    return res.json();
                }
                return undefined;
            }).then(data => {
                if (!data)
                    return data;
                this._totalResultsPages = data.totalResults / data.itemsPerPage;
                dispatch(setResults({ results: data.products, showMore: this._currentResultsPage < this._totalResultsPages, requestedArea: this._searchModel.geometry }));
                return data;
            });

        } catch (error) {
            return null;
        }
    };

    cartCheckout = (orders: CartItem[], token: any) => {
        const promises = orders.map(order => {
            switch (order.item.supplier) {
                case Supplier.SentinelHub:
                    return this.addToLibrary(order.item, token);
                case Supplier.SkyFi:

                    return this.purchase(order.item, token);
            }
        });
        return Promise.allSettled(promises);
    }

    showMoreResults = (token: string, dispatch: any) => {
        this._searchModel.startPage = ++this._currentResultsPage;
        this._searchModel.itemsPerPage = this.ITEMS_PER_PAGE;
        return fetch(`${this.discoverEP}/search`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify(this._searchModel),
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
        }).then(data => {
            this._totalResultsPages = data.totalResults / data.itemsPerPage;
            dispatch(updateResults({ results: data.products, showMore: this._currentResultsPage < this._totalResultsPages, requestedArea: this._searchModel.geometry }));
            return data;
        });
    };

    getGallery = (token: string) => {
        return fetch(`${this.discoverEP}/gallery`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                return [];
            }
        }).then(data => {
            return data;
        }).catch(error => console.log);
    };


    addToLibrary = async (orderData: OrderData, token: any) => {
        const model = {
            kind: "order.data.product",
            products: [
                {
                    productType,
                    radiometricProcessing: radiometricProcessing.reflectance,
                    imageFormat: imageFormat.geotiff,
                    id: orderData.id,
                    aoi: {
                        coordinates: orderData.coordinates,
                        type: "Polygon"
                    },
                    collection: orderData.collection,
                    supplier: orderData.supplier,
                    cloudCover: orderData.cloudCover
                    // sourceId?: string
                }
            ]
        };

        return fetch(`${this.discoverEP}/order/create`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`

            },
            body: JSON.stringify(model),
        }).then(res => {
            if (res.ok) {
                // this.getGallery(orderData.token);
                TosterSrv.Info(`Purchase completed`);
                return res;
            } else {
                throw res;
            }
        }).then(data => {
            return data;
        }).catch(error => {
            throw { error, id: model.products[0].id };
        });
    };

    purchase = async (orderData: OrderData, token: any) => {
        const model = {
            kind: "order.data.product",
            products: [
                {
                    productType,
                    radiometricProcessing: radiometricProcessing.reflectance,
                    imageFormat: imageFormat.geotiff,
                    id: orderData.id,
                    aoi: {
                        coordinates: orderData.coordinates,
                        type: "Polygon"
                    },
                    supplier: orderData.supplier
                    // sourceId?: string
                }
            ]
        };

        return fetch(`${this.discoverEP}/order/create`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`

            },
            body: JSON.stringify(model),
        }).then(res => {
            if (res.ok) {
                // this.getGallery(orderData.token);
                TosterSrv.Info(`Purchase completed`);
                return res;
            } else {
                throw res;
            }
        }).catch(error => {
            throw { error, id: model.products[0].id };
        });;
    };

    downloadZip = (result: any, token: string) => {
        // support for SkyFi folder structure
        let isFolder = false;
        if (result.supplier === 1)
            isFolder = !isFolder;

        return axios.post(`${this.discoverEP}/download`,
            JSON.stringify({
                orderId: result.orderId,
                deliveryId: result.orderId,
                isFolder
            }),
            {
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json",
                    "authorization": `Bearer ${token}`

                }
            }).then(async res => {
                return {
                    src: res,
                    name: result.id || result.deliveryId
                }
            });
    };

    getPriceQuote = (id: string, coordinates: any, productType: productType, supplier: any, token: string) => {
        const model = {
            productType,
            radiometricProcessing: radiometricProcessing.reflectance,
            imageFormat: imageFormat.geotiff,
            supplier,
            id,
            aoi: {
                coordinates,
                type: "Polygon"
            }
            // sourceId?: string
        };

        return fetch(`${this.discoverEP}/price`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`

            },
            body: JSON.stringify(model),
        }).then(res => {
            if (res.ok) {
                return res.json();
            } else {
                res.json().then(error => {
                    switch (error.errorCode) {
                        case "E0223":
                            TosterSrv.Error(error.message);
                            break;
                    }
                });
            }
        }).then(data => {
            return {
                price: data?.price?.amount,
                currency: data?.price?.amountUnit
            };
        });
    }

    requestImageFromAdmin = async (imageData: any, token: any) => {
        
        return fetch(`${this.discoverEP}/order/admin-request`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify(imageData)
        }).then(res => {
            if (res.ok) {
                TosterSrv.Info(`Image Requested!`);
                return res;
            } else {
                throw res;
            }
        }).catch(error => {
            throw { error, id: imageData?.id };
        });;
    };
}

export default OneAtlasService.getInstance();
