import React from "react";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from '@mui/material';

const CheckboxComponent = styled(Checkbox)(() => ({
    '&.Mui-checked': {
        color: 'white'
        // Optional: Customize as needed
    },
    '&.Mui-checked:hover': {
        // Optional: Customize as needed
    },
    '&.MuiCheckbox-colorPrimary': {
        color: 'inherit'
        // padding: '0 .4vw 0 0'
        // Optional: Customize as needed
    },
    '&.MuiCheckbox-colorSecondary': {
        // Optional: Customize as needed
    },
    '&:hover': {
        // Optional: Customize as needed
    },
    '& .MuiSvgIcon-root': {
        // border: `1px solid white`,
        fontSize: '1vw',
        // backgroundColor: 'white'
    }
}))

const FormControlLabelComponent = styled(FormControlLabel)(() => ({
    color: 'white',
    paddingRight: '.5vw',
    margin: 0,
    '& .MuiFormControlLabel-label': {
        fontSize: '.6vw'
    }
}));

const AppCheckbox = ({ label, checked, onChecked }: any) => {
    const onClick = () => {
        onChecked({ label, checked: !checked });
    }
    return (
        <FormControlLabelComponent control={<CheckboxComponent checked={checked} onChange={onClick} />} label={label} />
    );
}

export default AppCheckbox