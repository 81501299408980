import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

class ToastrService {
    private static instance: ToastrService | null;
    private constructor() {
        toastr.options = {
            positionClass: 'toast-bottom-right',
            hideDuration: 300,
            timeOut: 60000
        }
    }

    static getInstance(): ToastrService {
        if (!ToastrService.instance) {
            ToastrService.instance = new ToastrService();
        }
        return ToastrService.instance;
    }

    Info = (data: string) => {
        toastr.info(data);
    };

    InfoLink = (message: string, link: string) => {
        toastr.info(`<div><span>${message}</span><br/><a href="${link}" target="_blank">Link to image</a></div>`);
    }

    Success = (data: string) => {
        toastr.success(data);
    };

    Warning = (data: string) => {
        toastr.warning(data);
    };

    Error = (data: string) => {
        toastr.error(data);
    };
}

export default ToastrService.getInstance();