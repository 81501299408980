import React, { useState, useEffect, useContext } from "react";
import AppCheckbox from "../../../Shared/Components/Checkbox/checkbox";
import AppRoundButton from "../../../Shared/Components/Round-Button/roundButton";
import Slider from "@mui/material/Slider"
import { ThemeProvider } from '@mui/material';
import appTheme from "../../../../styles/app.theme";
import DatePicker from "react-datepicker";
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import OneAtlasService from "../../../../services/one-atlas.service";
import "react-datepicker/dist/react-datepicker.css";
import "./imagery-search-panel.css"
import mapContext from "../../../Map/mapContext";
import { MenuContext } from "../../../Layout/layout";
import { selectTypes, SearchModel } from "../../../Shared/Models/search-imagery-result.model";
import SearchBar from "../../../Search-bar/search";
import { useDispatch, useSelector } from "react-redux";
import ToastrService from "../../../../services/toster.service";
import { getToken, setResults } from "../../../../store/slices/gallerySlice";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

const ImagerySearchPanel = ({ position }: any) => {
    const title = "Search Data";

    const dispatch = useDispatch();
    const { setSelectionMode, cords, searchResults, setSelectedFile }: any = useContext(mapContext);
    const token = useSelector(getToken);
    const menuContex: any = useContext(MenuContext);

    const [resolutionSliderValue, setResolutionSliderValue]: any = useState([0, 100]);
    const [selectedReolution, setSelectedResolution]: any = useState([]);
    const [cloudsSliderValue, setCloudsSliderValue] = useState(0);
    const [startDate, setStartDate]: any = useState(null);
    const [endDate, setEndDate]: any = useState(null);
    const [isVisibale, setIsViibale]: any = useState(false);

    const sliderValuetext = (value: number) => {
        return `${value / 100}`;
    };
    const formatValue: any = {
        0: 0,
        20: 0.25,
        40: 0.5,
        60: 0.75,
        80: 1,
        100: 5
    }
    const resolutionSliderMarks: any[] = [
        {
            value: 0,
            label: '0',
        },
        {
            value: 20,
            label: '0.25',
        },
        {
            value: 40,
            label: '0.5',
        },
        {
            value: 60,
            label: '0.75',
        },
        {
            value: 80,
            label: '1'
        },
        {
            value: 100,
            label: '∞',
        },
    ];
    const sensors: any[] = [
        { label: "RGB", checked: true },
        { label: "PAN", checked: true },
        { label: "M.S", checked: true },
        { label: "SAR", checked: true }
    ];

    const [modelSensors, setModelSensors] = useState(sensors);

    useEffect(() => {
        setIsViibale(menuContex.menu.filter((item: any) => (item.title === title))[0].selected);
    }, [menuContex.menu]);

    useEffect(() => {
        if (cords.selectedCords) {
            moveToPanel('Search Data');
            searchResults.setSearchResultsModel([]);
        }
    }, [cords.selectedCords]);

    useEffect(() => {
        // document.getElementsByClassName('react-datepicker-wrapper')[0].children[0].children[1].placeholder = "From";
        // document.getElementsByClassName('react-datepicker-wrapper')[1].children[0].children[1].placeholder = "To";

        setSelectedResolution([0, 5])
    }, []);

    // const browseShape = () => {

    // };

    function resolutionValueLabelFormat(value: number) {
        return formatValue[value];
    }

    function cloudsCoverValueLabelFormat(value: number) {
        return `${value}%`;
    }

    const handelResolutionSliderChange = (event: any, oldValue: number[]) => {
        const newValue = event.target.value;
        if (!Array.isArray(newValue)) {
            return;
        }
        setResolutionSliderValue(newValue);
        setSelectedResolution([formatValue[newValue[0]], formatValue[newValue[1]]]);
    };

    const handelCloudCoverSliderChange = (event: any) => {
        setCloudsSliderValue(event.target.value);
    };

    const discoverClicked = () => {
        dispatch(setResults({
            results: [], showMore: false, requestedArea: {
                coordinates: [],
                type: ""
            }
        }));
        const searchModel = new SearchModel();
        searchResults.setSearchResultsModel([]);
        // searchModel.sortBy = "-acquisitionDate";
        searchModel.sensors = modelSensors.map(sensor => {
            if (sensor.checked) {
                return sensor.label;
            }
            return;
        }).filter(n => n);
        searchModel.processingLevel = "SENSOR";
        searchModel.cloudCover = cloudsSliderValue;
        searchModel.resolutionRange = selectedReolution;
        searchModel.dateRange = [startDate || new Date(0), endDate || new Date()];
        searchModel.geometry = {
            coordinates: cords.selectedCords,
            type: "Polygon"
        };
        OneAtlasService.searchResults(searchModel, token, dispatch)?.then((data: any) => {
            // searchResults.setSearchResultsModel(data);
            if (data.totalResults > 0)
                moveToPanel('Results Gallery');
            else
                ToastrService.Error("No results fonud.")
        });
    };

    const resetFilterClicked = () => {
        handelResolutionSliderChange(null, [0, 100]);
        setCloudsSliderValue(100);
        setStartDate(null);
        setEndDate(null);
    };

    const onSensorChacked = (item: any) => {
        const list = modelSensors.map(sensor => {
            if (sensor.label === item.label) {
                sensor.checked = item.checked;
            }
            return sensor;
        });
        setModelSensors(list);
    };

    const selectUsing = (event: any, shape: any) => {
        //event.preventDefault();
        setSelectionMode(shape);
    };

    const handleStartDateChange = (date: Date) => {
        setStartDate(date);
        if (endDate && date > endDate) {
            setEndDate(null);
        }
    };
    const handleEndDateChange = (date: Date) => {
        if (startDate && date < startDate) {
            return;
        }
        setEndDate(date);
    };

    const moveToPanel = (panel: any) => {
        menuContex.setMenu(menuContex.menu.map((mi: any) => {
            if (mi.title === panel) {
                mi.selected = true;
            } else {
                mi.selected = false
            }
            return mi;
        }));
    };

    const handleFileUpload = (event: any) => {
        setSelectedFile(event.currentTarget.files[0]);
    };

    return (
        <div className="search-imagery-data" style={{
            // position: 'absolute',
            //  {...handler()}
            // top: position.y,
            // left: position.x,
            display: isVisibale ? 'block' : 'none'
        }}>
            <div className="header">Search Imagery Data</div>
            <div className="body">
                <SearchBar></SearchBar>
                <div className="block">
                    <span className="title">Sensor</span>
                    <div className="content checkbox">
                        {modelSensors.map(sensor => {
                            return <AppCheckbox label={sensor.label} checked={sensor.checked} key={sensor.label} onChecked={onSensorChacked}></AppCheckbox>;
                        })};
                    </div>
                </div>
                <div className="block">
                    <span className="title">Area of Interest</span>
                    <div className="content shapes">
                        <Tippy content="Draw Box">
                            <span id="shape-square" className="shape square" onClick={event => selectUsing(event, selectTypes.square)}></span>
                        </Tippy>
                        <Tippy content="Draw polygon">
                            <span id="shape-poly" className="shape poly" onClick={event => selectUsing(event, selectTypes.poly)}></span>
                        </Tippy>
                        <Tippy content="Upload Layer">
                            <span id="shape-upload" className="shape upload">
                                <label htmlFor="fileInput" className="custom-upload-button">
                                </label>
                                <input type="file" id="fileInput" onChange={handleFileUpload}>
                                </input>
                            </span>
                        </Tippy>
                    </div>
                </div>
                <div className="block">
                    <span className="title">Date Range</span>
                    <div className="content date">
                        <div className="date-picker">
                            <DatePicker selectsStart selected={startDate} onChange={handleStartDateChange} />
                            <IconButton type="button" sx={{ p: '10px', fontSize: '2.7vh', display: 'flex', top: '-4.7vh', left: '0', padding: '0', marginLeft: 'auto' }} aria-label="search">
                                <CalendarMonthIcon sx={{ color: 'white', fontSize: 'inherit', fontWeight: '400' }} />
                            </IconButton>
                        </div>
                        <div className="date-picker">
                            <DatePicker selectsEnd selected={endDate} startDate={startDate} onChange={handleEndDateChange} />
                            <IconButton type="button" sx={{ p: '10px', fontSize: '2.7vh', display: 'flex', top: '-4.7vh', left: '0', padding: '0', marginLeft: 'auto' }} aria-label="search">
                                <CalendarMonthIcon sx={{ color: 'white', fontSize: 'inherit', fontWeight: '400' }} />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className="block">
                    <span className="title">Resolution Range</span>
                    <div className="content">
                        <div className="slider-container">
                            <Slider
                                sx={{}}
                                getAriaLabel={() => 'Resolution Range'}
                                value={resolutionSliderValue}
                                marks={resolutionSliderMarks}
                                step={null}
                                onChange={(event) => { handelResolutionSliderChange(event, resolutionSliderValue) }}
                                valueLabelFormat={resolutionValueLabelFormat}
                                valueLabelDisplay="auto"
                                getAriaValueText={sliderValuetext}
                                disableSwap
                            />
                        </div>
                    </div>
                </div>
                <div className="block no-border">
                    <span className="title">Clouds Cover</span>
                    <div className="content">
                        <div className="slider-container">
                            <Slider defaultValue={cloudsSliderValue} aria-label="Default"
                                valueLabelFormat={cloudsCoverValueLabelFormat}
                                onChange={(event) => { handelCloudCoverSliderChange(event) }}
                                valueLabelDisplay="auto" />
                        </div>
                    </div>
                </div>
                <div className="block button-container no-border">
                    <ThemeProvider theme={appTheme}>
                        {/* <span color={appTheme.appColors.appYellow}>THIS IS A TEST SPAN</span> */}
                        <AppRoundButton onClick={discoverClicked} color="primary" variant="contained" label="Go Discover"></AppRoundButton>
                        <AppRoundButton onClick={resetFilterClicked} color="secondary" variant="contained" label="Reset Filter"></AppRoundButton>
                    </ThemeProvider>
                </div>
            </div>
        </div >
    );
}

export default ImagerySearchPanel;