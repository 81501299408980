import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserStatus from "./status";
import "./credit_system.css";
import { deductPrice } from "../redux/actions";

const CreditSystem = () => {
  const balance = useSelector((state:any) => state.balance.data)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalPrice = useSelector((state:any) => state.update.data.totalPrice);

  const checkSuffice = ():boolean => balance >= totalPrice;

  const useCredits = () => {
    if(checkSuffice()){
      dispatch(deductPrice(Number(totalPrice)));
      navigate("../payments/confirm");
    }else {
      return;
    }
  };

  const loadCredits = () => navigate('../payments');

  return (
    <div>  
    <div className="credit-system">
      <div className="credits-display">
        <button onClick={useCredits} disabled={!checkSuffice()}>Use Credit</button>
      </div>
      <div className="or-divider">-or-</div>
      <button onClick={loadCredits} className="load-credit">
        Purchase Credit
      </button>

      
    </div>
    </div>
    
  );
};

export default CreditSystem;
