import React, { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../../Layout/layout";
import SearchImageryResult from "../Imagery-Result/imagery-result";
import OneAtlasService from "../../../../services/one-atlas.service";
import { useDispatch, useSelector } from "react-redux";
import { getSearchResults, getToken } from "../../../../store/slices/gallerySlice";
import "react-datepicker/dist/react-datepicker.css";
import "./imagery-results-panel.css";

const SearchImageryResults = ({ position }: any) => {
    const title = "Results Gallery";

    const token = useSelector(getToken);
    const dispatch = useDispatch();
    const searchResult = useSelector(getSearchResults);
    const menuContext: any = useContext(MenuContext);
    const [isVisibale, setIsVisibale] = useState(false);

    useEffect(() => {
        setIsVisibale(menuContext.menu.filter((item: any) => (item.title === title))[0].selected);
    }, [menuContext.menu]);

    function showMore() {
        if (searchResult?.showMore) {
            return (
                <div className="block more">
                    <div className="show-more" onClick={showMoreResults}>Show More</div>
                </div>
            );
        }
    }

    function showMoreResults() {
        // OneAtlasService.showMoreResults(token).then(data => {
        //     data.products = [...searchResults?.searchResultsModel?.products, ...data.products];
        //     searchResults.setSearchResultsModel(data);
        // });
        OneAtlasService.showMoreResults(token, dispatch);
    }

    if (searchResult?.results?.length === 0) {
        return (
            <div className="search-imagery-list" style={{
                display: isVisibale ? 'block' : 'none',
            }}>
                <div className="header"></div>
                <div className="body">
                    <span className="no-results">No Results</span>
                </div>
                <div className="footer"></div>
            </div>
        )
    } else {
        return (
            <div className="search-imagery-list" style={{
                display: isVisibale ? 'block' : 'none',
                // position: 'absolute',
                //  {...handler()}
                // top: position.y,
                // left: position.x
            }}>
                <div className="header"></div>
                <div className="body">
                    {searchResult?.results?.map((result: any, index: number) => {
                        const res = { ...result, requestedArea: searchResult.requestedArea }
                        return (
                            <SearchImageryResult result={res} keyRef={result.id} key={result.id}></SearchImageryResult>
                        )
                    })}
                    {
                        showMore()
                    }
                </div>
                <div className="footer"></div>
            </div>
        );
    }
}

export default SearchImageryResults;