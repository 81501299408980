import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartItem, CartItemStatus } from "../../../Shared/Models/search-imagery-result.model";

interface CartState {
    items: CartItem[];
}

const initialState: CartState = {
    items: [],
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action: PayloadAction<CartItem>) => {
            const newItem = {
                ...action.payload,
                id: state.items.length
            };

            state.items = [...state.items, newItem];
        },
        removeFromCart: (state, action: PayloadAction<number>) => {
            const newArr = state.items.filter(item => (item.id !== action.payload));

            state.items = newArr;
        },
        updateCartItemStatus: (state, action: PayloadAction<{ id: string, status: CartItemStatus, error?: string }>) => {
            const newArr = state.items.map<CartItem>(item => {
                if (item.item.id === action.payload.id) {
                    item.status = action.payload.status
                    return item;
                }
                return item;
            });

            state.items = newArr;
        },
        clearCart: (state, action) => {
            state.items = [];
        },
        checkoutCart: (state, action) => {
            state.items = [];
        }
    }
});

export const { addToCart, removeFromCart, updateCartItemStatus, clearCart, checkoutCart } = cartSlice.actions;

export const getCartItemsState = (state: any) => state.cart.items;
// Create a selector using createSelector
export const selectCartItems = createSelector(
    getCartItemsState,
    (items) => items
);

// Create a selector using createSelector
export const pendingCartItems = createSelector(
    getCartItemsState,
    (items) => items.filter((item: CartItem) => item.status !== 'Completed')
);

// Create a selector using createSelector
export const selectCartTotalPrice = createSelector(
    getCartItemsState,
    (items) => items.reduce((total: number, item: CartItem) => total + item.price, 0)
);


export default cartSlice.reducer;