import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartItem, CartItemStatus } from "../../../Shared/Models/search-imagery-result.model";

interface CardState {
    isCardInit: boolean,
    data: any,
    cardElementConfig: any
}

const initialState: CardState = {
    isCardInit: false,
    data: null,
    cardElementConfig: {}
};

const cardDataSlice = createSlice({
    name: 'cardData',
    initialState,
    reducers: {
        setCardData: (state, action) => {
            state.data = action.payload;
        },
        clearCardData: (state) => {
            state.data = null;
        },
        initializeCardElement: state => {
            state.isCardInit = true;
        },
        setCardElementConfig: (state, action) => {
            state.cardElementConfig = action.payload;
        },
    },
});
export const { setCardData, clearCardData, initializeCardElement, setCardElementConfig } = cardDataSlice.actions;

export default cardDataSlice.reducer;