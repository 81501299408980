import React, { useEffect, useState } from 'react';
import './order_confirmation.css';
import { useNavigate } from 'react-router-dom';

const OrderConfirmation = () => {
    const [counter, setCounter] = useState(5);
    useEffect(() => {

        const interval = setInterval(() => {
          setCounter((prevCounter) => prevCounter - 1);
        }, 1000);
    

        setTimeout(() => {
          navigate('/payments'); 
        }, 5000);
    
  
        return () => {
          clearInterval(interval);
        };
      }, []);
    const navigate = useNavigate();
  return (
    <div className="order-confirmation">
      <div className="redirect-message">
        Auto redirect in {counter}s
      </div>
        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
          <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
 
      <h1>Hey Yarin,</h1>
      <p className="confirmation-message">
        Your order is confirmed<br/>
        We'll send you a confirmation email with your order’s details.
      </p>
      <button onClick={() => navigate('/payments')}>Back to Dashboard</button>
    </div>
  );
};

export default OrderConfirmation;
