import { useState } from 'react';
import styled from 'styled-components';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Login from '@mui/icons-material/Login';
import Cart from '@mui/icons-material/ShoppingCart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Logout from '@mui/icons-material/Logout';
import { User, useAuth0 } from "@auth0/auth0-react";
import TopMenu from '../Top-menu/top-menu';
import { MenuFormat } from "../Shared/Models/search-imagery-result.model";
import packageJson from '../../../package.json'; // replace with the path to your package.json file
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { pendingCartItems } from '../Payments/redux/slices/cartSlice';
import { config } from "../../config/config";

// Styled components
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding: 1rem;
  height: auto;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    height: 13px;
  }
`;

const Logo = styled.div``;

const VersionText = styled.p`
  color: white;
  margin-right: 3rem;
  font-size: 0.8rem;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: white;
  @media (min-width: 768px) {
    justify-content: space-between;
  }

`;

const Icon = styled.div`
  margin-right: 1.5rem;

  &:last-child {
    margin-right: 0;
  }
`;
// Component
const Header = () => {
  const { logout, isAuthenticated, loginWithRedirect } = useAuth0();
  const [user, setUser] = useState<User | null>(useAuth0());
  const isAdmin = user?.user?.email === config.DISCOVER_ADMIN_EMAIL;
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const cartItems = useSelector(pendingCartItems);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  }

  const [menuItems] = useState([
    { title: 'Login', class: 'menu search-map', icon: Login, customFunction: (() => loginWithRedirect()), isVisibale: !isAuthenticated },
    {
      title: 'Logout', class: 'menu gallery', icon: Logout, customFunction: (() => logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      })), isVisibale: isAuthenticated
    },
    {
      title: "Payments", class: '', icon: CreditCardIcon, customFunction: (() => {
        navigate('payments');
        setShowMenu(false)
      }), isVisibale: isAdmin
    },
    {
      title: "Shopping Cart", class: '', icon: Cart, customFunction: (() => {
        navigate('payments/cart');
        setShowMenu(false)
      }), isVisibale: isAdmin
    }
  ]);
  return (
    <HeaderWrapper>
      <Logo className="App-logo" onClick={() => navigate('/')} />
      <IconWrapper>
        <VersionText>Version: {packageJson.version}</VersionText>
        {
          isAdmin && (
            <Icon onClick={() => navigate('payments/cart')}><Cart /><span>{cartItems?.length}</span></Icon>
          )
        }
        <Icon><NotificationsOutlinedIcon /></Icon>
        <Icon onClick={toggleMenu}>
          <AccountCircleOutlinedIcon />
        </Icon>
        <TopMenu position={{ x: 0, y: 0 }} format={MenuFormat.textAndIcons} items={menuItems} isVisibale={showMenu}></TopMenu>
      </IconWrapper>
    </HeaderWrapper>
  );
}
export default Header;

