
import { createTheme, responsiveFontSizes } from "@mui/material";
let appTheme = createTheme({
    typography: {
        fontSize: 14
    },
    // appColors: {
    //     appYellow: "#FFFF00",
    //     appPink: "#FFC0CB"
    // },
    palette: {
        primary: {
            main: "#1976d2",
            light: "#1976d2",
            dark: "#1976d2",
            contrastText: "#fff"
        },
        secondary: {
            main: "#3EA912",
            light: "#3EA912",
            dark: "#3EA912",
            contrastText: "#fff"
        },
        // grey: {

        //     main: "#808080",
        //     light: "#808080",
        //     dark: "#808080",
        //     contrastText: "#fff"
        // }
        // appBlue: {
        //     main: "#1976d2",
        //     light: "#1976d2",
        //     dark: "#1976d2",
        //     contrastText: "#fff"
        // },
        // appGreen: {
        //     main: "#3EA912",
        //     light: "#3EA912",
        //     dark: "#3EA912",
        //     contrastText: "#fff"
        // },
        // appGrey: {
        //     main: "#808080",
        //     light: "#808080",
        //     dark: "#808080",
        //     contrastText: "#fff"
        // }
    }
});

appTheme = responsiveFontSizes(appTheme);

export default appTheme;