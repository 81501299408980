import { MouseEvent, createContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import LeftMenuNavigation from '../left-menu/left-menu';
import Router from '../Router/router';
import Header from './header';
import { useAuth0 } from "@auth0/auth0-react";
import Login from '../Login/login';
import { setToken, getToken, isResultsDisabled, isGalleryDisabled, isSearchDisabled, updateGallery } from '../../store/slices/gallerySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import oneAtlasService from '../../services/one-atlas.service';

export const MenuContext = createContext({});

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
background-color: black;
width: 45px;
padding-top: 25px;

  @media (max-width: 768px) {
    min-width: 0;
    width: 100%;
  }
`;

const MainContent = styled.div`
  flex: 1;
  padding: 0;
  height: 'inherit'

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Layout = () => {
  const { isAuthenticated } = useAuth0();
  const [isUrlToken, setIsUrlToken] = useState<boolean>(false);
  const tok = useSelector(getToken);
  const dispatch = useDispatch();
  // const { openPopup } = useContext(PopupContext);
  const [menuItems, setMenuItems] = useState([
    { title: 'Search Data', class: 'menu search-map', customFunction: (() => { console.log('Search Data') }), selected: true, disableSelector: isSearchDisabled },
    { title: 'Results Gallery', class: 'menu gallery', customFunction: (() => { console.log('Results Gallery') }), selected: false, disableSelector: isResultsDisabled },
    { title: 'Library', class: 'menu library', customFunction: (() => { console.log('Library') }), selected: false, disableSelector: isGalleryDisabled }
  ]);
  const nav = useNavigate();

  useEffect(() => {
    if (!tok) {
      const url = window.location;
      const acst = new URLSearchParams(url.search).get('token');
      if (acst) {
        setIsUrlToken(true);
        dispatch(setToken(acst))
        oneAtlasService.getGallery(acst).then(data => dispatch(updateGallery(data)));
        // Redirect to the origin URL (remove token from url)
        nav("/");
      }
    } else {
      oneAtlasService.getGallery(tok).then(data => { dispatch(updateGallery(data)) });
    }
  }, [dispatch, nav, tok]);

  function handlePopup(e: MouseEvent<Element>) {
    // openPopup(content ? content : <div>blabla</div>);
  };
  function setMenu(menu: any) {
    setMenuItems(menu);
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
        height: '100vh'
      }}
    >
      {(isAuthenticated || isUrlToken) && (
        <><Header />
          <ContentWrapper>
            <MenuContext.Provider value={{
              menu: menuItems,
              setMenu: setMenu
            }}>
              <Sidebar>
                <LeftMenuNavigation />
              </Sidebar>
              <MainContent className='main-content' onClick={(event) => { handlePopup(event) }}>
                <Router>
                </Router>
              </MainContent>
            </MenuContext.Provider>
          </ContentWrapper>
        </>
      ) || (
          <Login></Login>
        )}
    </div>
  );
};

export default Layout;
