class CompanyService {
    private static instance: CompanyService | null = null;
    private constructor() { }

    static getInstance(): CompanyService {
        if (!CompanyService.instance) {
            CompanyService.instance = new CompanyService();
        }
        return CompanyService.instance;
    }

    cc = [
        { company_id: 0, companyName: "AirBus", color: "rgb(255 0 0 / 80%)" },
        { company_id: 1, companyName: "SkyFi", color: "rgb(58 63 123 / 80%)" },
        { company_id: 2, companyName: "Sentinel", color: "rgb(36 164 36 / 80%)" },
        { company_id: 3, companyName: "Company 3", color: "blue" },
        { company_id: 4, companyName: "Company 4", color: "orange" }];

    companyColor = (index: number) => {
        return this.cc[index].color;
    };
    companyName = (id: number) => {
        return this.cc.find(c => c.company_id === id)?.companyName;
    };

}

export default CompanyService.getInstance();