import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import "./login.css";

const Login = () => {
    const { loginWithRedirect } = useAuth0();
    const handleClick = () => {
        loginWithRedirect();
    };
    return (
        <div className="login-container">
            <div className='logo-container'>
                <img className='splash-logo' src='logo.png' alt='logo'></img>
                <span>World class Imagery data search engine</span>
                <a href="" onClick={handleClick} className="splash-btn"><span>Go Discover</span></a>
            </div>
            <video loop autoPlay muted style={{ width: 'inherit' }}>
                <source src="Videos/pexels-intro-540p.webm" type="video/webm" />
                <source src="Videos/pexels-intro-540p.mp4" type="video/mp4" />
                Sorry, your browser doesn't support videos.
            </video>
            {/* <div className="banner"></div>
            <div className="content"></div>
            <div className="footer"></div> */}
        </div>
    )
}

export default Login