import "../Imagery-Results-Panel/imagery-results-panel"
import mapContext from "../../../Map/mapContext";
import React, { useContext, useState } from "react";
import OneAtlasService from "../../../../services/one-atlas.service";
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useDispatch, useSelector } from "react-redux";
import CompanyService from "../../../../services/company.service";
import { CartItem, OrderData, PriceModel, Supplier } from "../../../Shared/Models/search-imagery-result.model";
import "./imagery-result.css";
import { getToken } from "../../../../store/slices/gallerySlice";
import { addToCart } from "../../../Payments/redux/slices/cartSlice";
import oneAtlasService from "../../../../services/one-atlas.service";
import { User, useAuth0 } from "@auth0/auth0-react";
import { config } from "../../../../config/config";


const SearchImageryResult = ({ result, keyRef }: any) => {
    const skipCartMode = true;
    const { onResultHover, onHoverDone, cords }: any = useContext(mapContext);
    const [priceModel, setPriceModel] = useState<PriceModel | null>(null);
    const [user, setUser] = useState<User | null>(useAuth0());
    const isAdmin = user?.user?.email === config.DISCOVER_ADMIN_EMAIL;
    const [error, setError] = useState("");
    const [imageryRes, setImageryRes] = useState({ ...result, isPendingPurchase: false, purchased: false, inCart: false });
    const [loading, setLoading] = useState(false);
    const [requestStatus, setRequestStatus] = useState('idle'); // 'idle', 'success', 'error'
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    const mouseOver = (event: any, result: any) => {
        event.stopPropagation();
        onResultHover(result);
    };
    const mouseOut = (event: any) => {
        event.stopPropagation();
        onHoverDone();
    };

    const getPrice = () => {
        OneAtlasService.getPriceQuote(imageryRes.id, cords.selectedCords, imageryRes.productType, imageryRes.supplier, token).then((res) => {
            if (res.price >= 0) {
                setPriceModel(res);
                imageryRes.price = res.price;
                imageryRes.currency = res.currency;
                setError("");
            } else {
                setError("Unable to price!");
            }
        });
    };

    const purchase = (result: any) => {
        const orderData: OrderData = {
            id: result.id,
            coordinates: cords.selectedCords,
            productType: result.productType,
            supplier: result.supplier
        }
        const cartItem: any = {
            item: orderData,
            thumbnailUrl: result.thumbnailUrl,
            price: priceModel?.price || 0,
            status: "Pending",
            currency: priceModel?.currency || "USD"
        }

        dispatch(addToCart(cartItem));
        setImageryRes({ ...result, isPendingPurchase: true, purchased: false });
    };

    const addToLibrary = (result: any) => {
        const orderData: OrderData = {
            id: result.id,
            coordinates: cords.selectedCords,
            productType: result.productType,
            collection: result.collection,
            supplier: result.supplier,
            cloudCover: result.cloudCover
        }
        const cartItem: any = {
            item: orderData,
            thumbnailUrl: result.thumbnailUrl,
            price: priceModel?.price || 0,
            status: "Pending",
            currency: priceModel?.currency || "USD"
        }

        dispatch(addToCart(cartItem));
        setImageryRes({ ...result, isPendingPurchase: false, purchased: false });
    }

    const addResultToCart = (result: any) => {
        const orderData: OrderData = {
            id: result.id,
            coordinates: cords.selectedCords,
            productType: result.productType,
            collection: result.collection,
            supplier: result.supplier,
            cloudCover: result.cloudCover
        }
        const cartItem: any = {
            item: orderData,
            thumbnailUrl: result.thumbnailUrl,
            price: priceModel?.price || 0,
            status: "Pending",
            currency: priceModel?.currency || "USD"
        }

        dispatch(addToCart(cartItem));
        setImageryRes({ ...result, isPendingPurchase: false, purchased: false, inCart: true });
    };

    const skipCartOnPurchase = async (result: any) => {
        try {
            setLoading(true);

            const orderData: OrderData = {
                id: result.id,
                coordinates: cords.selectedCords,
                productType: result.productType,
                collection: result.collection,
                supplier: result.supplier,
                cloudCover: result.cloudCover
            }
            const cartItem: any = {
                item: orderData,
                thumbnailUrl: result.thumbnailUrl,
                price: priceModel?.price || 0,
                status: "Pending",
                currency: priceModel?.currency || "USD"
            }

            const res = await oneAtlasService.cartCheckout([cartItem], token);
            if (res.filter(result => result.status === 'rejected')?.length > 0) {
                throw new Error("Error ordering image");
            }

            let isPurchased = false;
            let isPendingPurchase = false;

            switch (orderData.supplier) {
                case Supplier.SentinelHub:
                    isPurchased = true;
                    break;
                case Supplier.SkyFi:
                    isPendingPurchase = true;
                    break;
            }

            setImageryRes({ ...result, isPendingPurchase: isPendingPurchase, purchased: isPurchased, inCart: false });
            setError("");
            setRequestStatus('success');
            setLoading(false);
        } catch (error) {
            setError("Order Failed");
            setRequestStatus('error');
            setLoading(false);
        }
    };

    const handleRequestImageClick = (event: any) => {
        event.stopPropagation();
        requestImageFromAdmin(imageryRes);
    };

    const requestImageFromAdmin = (result: any) => {
        setLoading(true);

        const orderData: OrderData = {
            id: result.id,
            coordinates: cords.selectedCords,
            productType: result.productType,
            collection: result.collection,
            supplier: result.supplier,
            cloudCover: result.cloudCover
        }
        const cartItem: any = {
            item: orderData,
            thumbnailUrl: result.thumbnailUrl,
            price: priceModel?.price || 0,
            status: "Pending",
            currency: priceModel?.currency || "USD"
        }

        const data = { ...result, userEmail: user?.user?.email, requestedArea: result.requestedArea };
        OneAtlasService.requestImageFromAdmin(data, token).then((res) => {
            setError("");
            setRequestStatus('success');
            setLoading(false);
        }).catch(err => {
            setError("Order Failed");
            setRequestStatus('error');
            setLoading(false);
        });
    };

    return (
        <div key={keyRef} className="block" onMouseOver={(event) => { mouseOver(event, imageryRes) }} onMouseOut={(event) => { mouseOut(event) }}>
            <div className="left-container">
                <div className="image-container">
                    <img src={imageryRes?.thumbnailUrl}></img>
                </div>
            </div>
            <div className="content">
                <div className="name">
                    <span className="company-label" style={{ backgroundColor: CompanyService.companyColor(imageryRes?.supplier) }}>{CompanyService.companyName(imageryRes?.supplier)}</span>&nbsp;<span>{imageryRes?.sensorType}</span>
                </div>
                <span style={{ paddingTop: '5px' }}>{
                    new Date(imageryRes?.acquisitionDate).toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
                }</span>
                <p><span>Id: {imageryRes?.id}</span></p>
                <p><span>Resolution: {imageryRes?.resolution || 0}cm</span></p>
                <p><span>Cloud coverage: {Math.round(imageryRes?.cloudCover)}%</span></p>
                <span>Angle: {Math.round(imageryRes?.azimuthAngle)}</span>
                <div className="buttons-container">
                    {
                        imageryRes.inCart === true && (
                            <ShoppingCartIcon sx={{ fontSize: '11pt' }} />
                        )
                    }
                    {
                        !priceModel && !imageryRes?.openData && cords.totalAreaSelected >= imageryRes.minSqKm && !isAdmin && (
                            <div style={{ width: '100%', border: '1px solid white', padding: '4px 5px 5px 5px', display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }}>
                                <span onClick={getPrice} style={{ justifySelf: 'flex-start' }}>Check Availability</span>
                            </div>
                        )
                    }
                    {
                        !priceModel && !imageryRes?.openData && cords.totalAreaSelected >= imageryRes.minSqKm && isAdmin && (
                            <div style={{ width: '100%', border: '1px solid white', padding: '4px 5px 5px 5px', display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }}>
                                <span onClick={getPrice} style={{ justifySelf: 'flex-start' }}>Get price</span>
                            </div>
                        )
                    }
                    {
                        !priceModel && cords.totalAreaSelected < imageryRes.minSqKm && (
                            <span className="error" style={{ display: 'block' }}>{`Min ${imageryRes.minSqKm} sqkm`}</span>
                        )
                    }
                    {
                        !imageryRes?.inCart && imageryRes?.openData && (
                            <>
                                <div onClick={(event) => { skipCartMode ? skipCartOnPurchase(imageryRes) : addResultToCart(imageryRes) }} style={{ width: '100%', border: '1px solid white', padding: '4px 5px 5px 5px', display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }}>
                                    {!skipCartMode ? (
                                        <span>
                                            <span style={{ justifySelf: 'flex-start' }}>FREE</span>
                                            <AddShoppingCartIcon sx={{ justifySelf: 'flex-end', fontSize: '11pt' }} />
                                        </span>
                                    ) : loading ? (
                                        <span style={{ display: "flex", alignItems: "center", paddingLeft: "2%" }}>
                                            <span>Ordering</span>
                                            <CircularProgress />
                                        </span>
                                    ) : requestStatus === 'success' ? (
                                        <span style={{ display: "flex", paddingLeft: "2%" }}><span style={{ alignContent: "center" }}>Ordered </span><CheckCircleOutlineIcon style={{ color: 'green', marginLeft: '5px', width: '29%' }} /></span>
                                    ) : (
                                        <span style={{ justifySelf: 'flex-start' }}>FREE</span>
                                    )}
                                </div>
                            </>
                        )
                    }
                    {
                        priceModel && !imageryRes?.inCart && !imageryRes?.openData && isAdmin && (
                            <>
                                <div onClick={(event) => { addResultToCart(imageryRes) }} style={{ width: '100%', border: '1px solid white', padding: '4px 5px 5px 5px', display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }}>
                                    <span style={{ justifySelf: 'flex-start' }}>{imageryRes.currency} {imageryRes.price}</span>
                                    <AddShoppingCartIcon sx={{ justifySelf: 'flex-end', fontSize: '11pt' }} />
                                </div>
                            </>
                        )
                    }
                    {
                        priceModel && !imageryRes?.inCart && !imageryRes?.openData && !isAdmin && (
                            <>
                                <div onClick={handleRequestImageClick} style={{ width: '100%', border: '1px solid white', display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }}>
                                    {loading ? (
                                        <span style={{ display: "flex", alignItems: "center", paddingLeft: "2%" }}>
                                            <span>Ordering</span>
                                            <CircularProgress />
                                        </span>
                                    ) : requestStatus === 'success' ? (
                                        <span style={{ display: "flex", paddingLeft: "2%" }}><span style={{ alignContent: "center" }}>Ordered </span><CheckCircleOutlineIcon style={{ color: 'green', marginLeft: '5px', width: '29%' }} /></span>
                                    ) : (
                                        <span style={{ justifySelf: 'flex-start', margin: '4px 5px 5px 5px' }}>Order</span>
                                    )}
                                </div>
                            </>
                        )
                    }
                    <span className="error" style={{ display: error ? 'block' : 'none' }}>{error}</span>
                </div>
            </div>
        </div >
    );
}

export default SearchImageryResult;