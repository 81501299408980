
import React, { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../../Layout/layout";
import ImageryLibrary from "../imagery-Library/imagery-library";
import { getGallery } from "../../../../store/slices/gallerySlice";
import { useSelector } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import "./imagery-library-panel.css";

const SearchImageryLibrarly = ({ position, key }: any) => {
    const title = "Library";

    // const token = useSelector(getToken);
    const galleryResults = useSelector(getGallery);
    const menuContext: any = useContext(MenuContext);
    const [isVisibale, setIsVisibale] = useState(false);

    useEffect(() => {
        // OneAtlasService.getGallery(token);
        //galleryStore.dispatch(getGallery);
    }, []);

    useEffect(() => {
        setIsVisibale(menuContext.menu.filter((item: any) => (item.title === title))[0].selected);
    }, [menuContext.menu]);

    return (
        <div className="search-imagery-list" style={{
            display: isVisibale ? 'block' : 'none',
        }}>
            <div className="header"></div>
            <div className="body">
                {galleryResults?.map((result: any, index: number) => {
                    return (
                        <ImageryLibrary result={result} key={index}></ImageryLibrary>
                    )
                })}
            </div>
            <div className="footer"></div>
        </div>
    );
}

export default SearchImageryLibrarly;