import mapContext from "../../../Map/mapContext";
import React, { useContext, useState } from "react";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress } from '@mui/material';
import OneAtlasService from "../../../../services/one-atlas.service";
import { OrderStatus } from "../../../Shared/Models/search-imagery-result.model";
import { useSelector } from "react-redux";
import CompanyService from "../../../../services/company.service";
import { getToken } from "../../../../store/slices/gallerySlice";
import "../Imagery-Results-Panel/imagery-results-panel"


const ImageryLibrary = ({ result }: any) => {
    const { onLibraryHover, onHoverDone, lightbox }: any = useContext(mapContext);
    const token = useSelector(getToken);
    const [error, setError] = useState(null);
    const [downloading, setDownloading] = useState<string[]>([]);

    const mouseOver = (event: any, result: any) => {
        event.stopPropagation();
        onLibraryHover(result);
    };
    const mouseOut = (event: any) => {
        event.stopPropagation();
        onHoverDone();
    };

    const mouseClick = (event: any, result: any) => {
        event.stopPropagation();
        onLibraryHover(result);
    };

    const downloadUsingFetch = async (image: any) => {
        let blob: Blob;
        if (image.src.data.type === "binary/octet-stream") {
            blob = new Blob([image.src.data], { type: "application/zip" });
        } else {
            blob = image.src.data;
        }
        const imageURL = URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = imageURL;
        anchor.download = image.name;

        document.body.appendChild(anchor);
        anchor.click();
    }

    const download = (result: any) => {
        setDownloading([...downloading, result.id]);
        OneAtlasService.downloadZip(result, token).then(image => {
            const d = downloading.filter(id => id !== result.id);
            setDownloading(d);
            downloadUsingFetch(image);
        });
    };

    const preview = (result: any) => {
        lightbox.setSrc(result.thumbnailUrl);
        lightbox.toggle(true);
    };

    return (
        <div className="block">
            <div className="left-container">
                <div className="image-container">
                    <SearchIcon className="image-preview-icon" onClick={(event) => { preview(result) }}></SearchIcon>
                    <img src={result?.thumbnailUrl}></img>
                </div>
            </div>
            <div className="content">
                <div className="name">
                    <span className="company-label" style={{ backgroundColor: CompanyService.companyColor(result?.supplier) }}>{CompanyService.companyName(result?.supplier)}</span>&nbsp;<span className="capture-sensor">{result?.sensor}</span>
                    <MyLocationIcon className="icon" onClick={(event) => { mouseClick(event, result) }} sx={{ fontSize: '16px', marginLeft: 'auto' }} />
                </div>
                <span style={{ paddingTop: '5px' }}>{
                    new Date(result?.createdDate).toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })
                }</span>
                <p><span>Cloud coverage: {Math.round(result?.cloudCover)}%</span></p>
                <p><span>Resolution: {result?.resolution || 0}cm</span></p>
                <span>Angle: {Math.round(result?.azimuthAngle)}</span>
                <div className="buttons-container">
                    {result.status === OrderStatus.delivered && !downloading.includes(result.id) &&
                        // <button onClick={(event) => { download(result) }} className="download-btn">Download</button>
                        <div style={{ width: '100%', border: '1px solid white', padding: '4px 5px 5px 5px', display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }}>
                            <span onClick={(event) => { download(result) }} style={{ justifySelf: 'flex-start' }}>Download</span>
                        </div>
                    }
                    {downloading.includes(result.id) &&
                        <CircularProgress sx={{ marginRight: "25px" }} />
                    }
                    {result.status === OrderStatus.ordered &&
                        // <button className="download-btn pending" disabled>Pending</button>
                        <div style={{ width: '100%', border: '1px solid white', padding: '4px 5px 5px 5px', display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <span style={{ justifySelf: 'flex-start' }}>Pending</span>
                        </div>
                    }
                    <span className="error" style={{ display: error ? 'block' : 'none' }}>{error}</span>
                </div>
            </div>
        </div>
    );
}

export default ImageryLibrary;