declare var configuration: any;

export const config = {
    REACT_APP_AUTH0_CLIENT_ID:
        process.env.NODE_ENV === 'production' ? configuration.REACT_APP_AUTH0_CLIENT_ID : process.env.REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_AUTH0_DOMAIN:
        process.env.NODE_ENV === 'production' ? configuration.REACT_APP_AUTH0_DOMAIN : process.env.REACT_APP_AUTH0_DOMAIN,
    REACT_APP_MAPBOX_TOKEN:
        process.env.NODE_ENV === 'production' ? configuration.REACT_APP_MAPBOX_TOKEN : process.env.REACT_APP_MAPBOX_TOKEN,
    REACT_APP_SOCKETIO_ENDPOINT:
        process.env.NODE_ENV === 'production' ? configuration.REACT_APP_SOCKETIO_ENDPOINT : process.env.REACT_APP_SOCKETIO_ENDPOINT,
    REACT_APP_SATELLITE_SERVICE:
        process.env.NODE_ENV === 'production' ? configuration.REACT_APP_SATELLITE_SERVICE : process.env.REACT_APP_SATELLITE_SERVICE,
    DISCOVER_ADMIN_EMAIL: "shoval@mobilegroup.co.il"
};