
import { BalanceActionTypes, CHARGE_TOKENS, DEDUCT_PRICE, INCREMENT_PRICE, SET_PAYMENT_INTENT, SET_USER_INFO, SetPaymentIntentAction, UPDATE_DATA } from './actions';
import { CartItem } from '../../Shared/Models/search-imagery-result.model';

const initialState = {
    data: {
        totalPrice: 0
    }
};

const balanceState: { data: number } = {
    data: 0
}

interface PaymentIntentState {
    amount: number;
    currency: string;
    customer: string;
    description: string;
    createdTimestamp: number;
}

const initialPaymentIntentState = {
    paymentIntents: []
};

const userInitialState = {
    data: {}
};

export const paymentIntentReducer = (state = initialPaymentIntentState, action: any) => {
    switch (action.type) {
        case SET_PAYMENT_INTENT:
            return {
                ...state,
                paymentIntents: [...state.paymentIntents, action.payload]
            };
        default:
            return state;
    }
};

const updateDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_DATA:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
};

export const deductPriceReducer = (state = balanceState, action: BalanceActionTypes) => {
    switch (action.type) {
        case DEDUCT_PRICE:
            return {
                ...state,
                data: state.data - action.payload
            };
        case INCREMENT_PRICE:
            return {
                ...state,
                data: state.data + Number(action.payload)
            };
        default:
            return state;
    }
};

export const userInfoReducer = (state = userInitialState, action: any) => {
    switch (action.type) {
        case SET_USER_INFO:
            return {
                ...state,
                info: action.payload
            }


        default:
            return state;
    }
};


export const chargeTokensReducer = (state = { data: false }, action: any) => {
    switch (action.type) {
        case CHARGE_TOKENS:
            return { ...state, data: action.payload }
        default:
            return state;

    }
};

export default updateDataReducer;

