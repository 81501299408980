import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartItem, CartItemStatus } from "../../components/Shared/Models/search-imagery-result.model";
import OneAtlasService from "../../services/one-atlas.service";
import { useDispatch } from 'react-redux';

interface CartState {
    items: CartItem[];
}

const initialState: CartState = {
    items: [],
};

const gallerySlice = createSlice({
    name: 'gallery',
    initialState: {
        token: "",
        searchResults: {
            results: [],
            showMore: false,
            requestedArea: {
                coordinates: [],
                type: ""
            }
        },
        gallery: []
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        updateGallery: (state, action) => {
            state.gallery = action.payload;
        },
        setResults: (state, action) => {
            state.searchResults.results = action.payload.results;
            state.searchResults.showMore = action.payload.showMore;
            state.searchResults.requestedArea = action.payload.requestedArea;
        },
        updateResults: (state, action) => {
            state.searchResults.results = [...state.searchResults.results, ...action.payload.results] as [];
            state.searchResults.showMore = action.payload.showMore;
            state.searchResults.requestedArea = action.payload.requestedArea;
        }
    }
});
export const { updateGallery, setToken, setResults, updateResults } = gallerySlice.actions;

// Select the desired data from the state
const getTokenFromState = (state: any) => state.gallery.token;

// Select the desired data from the state
const getGalleryFromState = (state: any) => state.gallery.gallery;

// Select the desired data from the state
const getResultsFromState = (state: any) => state.gallery.searchResults;

// Create a selector using createSelector
export const getToken = createSelector(
    getTokenFromState,
    (token) => token
);

// Create a selector using createSelector
export const getSearchResults = createSelector(
    getResultsFromState,
    (searchResults) => searchResults
);

// Create a selector using createSelector
export const isSearchDisabled = createSelector(
    () => false
);
// Create a selector using createSelector
export const isResultsDisabled = createSelector(
    getResultsFromState,
    (searchResults) => searchResults?.results.length === 0
);

// Create a selector using createSelector
export const getGallery = createSelector(
    getGalleryFromState,
    (gallery) => gallery
);

// Create a selector using createSelector
export const isGalleryDisabled = createSelector(
    getGalleryFromState,
    (gallery) => gallery?.length === 0
);

export default gallerySlice.reducer;