import { CheckedItems } from "./cart";
import "./checkout_summary.css";
import DownloadIcon from "@mui/icons-material/Download";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  CartItem,
  productType,
} from "../../Shared/Models/search-imagery-result.model";
import { useEffect, useState } from "react";
import { selectCartItems } from "../redux/slices/cartSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface CheckoutSummaryProps {
  selectedIds: number[];
}

const CheckoutSummary = ({ selectedIds }: CheckoutSummaryProps) => {
  const payedItems = useSelector(selectCartItems);
  const filteredItems = payedItems.filter((item: any) =>
    selectedIds.includes(item.id)
  );
  const navigate = useNavigate();

  return (
    <div className="checkout-summary">
      <table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Amount</th>
            <th>Product ID</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map((item: CartItem, i: number) => (
            <tr key={item.id || i}>
              <td>
                <span className={`${item.status}`}>{item.status}</span>
              </td>
              <td>{item.price}</td>
              <td>{item.id}</td>
              <td>
                <button className="small-button">
                  <DownloadIcon />
                </button>
                <button className="small-button">
                  <ReplayIcon />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="table-actions">
        <button
          onClick={() => navigate("/payments/confirm")}
          disabled={
            !filteredItems.some((item: CartItem) => item.status === "Completed")
          }
          className="small-button"
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

export default CheckoutSummary;
