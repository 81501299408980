import React from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material';
// import styled, { css } from 'styled-components';
import "./roundButton.css";

const RoundButtonComponent = styled(Button)(() => ({
    borderRadius: 12,
    width: '12vw',
    whiteSpace: 'nowrap',
    lineHeight: '1.3',
    fontSize: '.8rem',
    textTransform: 'none',
    // Hover styles
    '&:hover': {
        backgroundColor: '#28E03A',
    },
}))

const AppRoundButton = ({ onClick, color, variant, label }: any) => {
    return (
        <RoundButtonComponent onClick={onClick} color={color} variant={variant}>{label}</RoundButtonComponent>
    );
}

export default AppRoundButton;