import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Map from 'ol/Map';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style.js';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Draw, { createBox } from 'ol/interaction/Draw';
import { selectTypes } from "../Shared/Models/search-imagery-result.model";
import mapContext from "./mapContext"
import SearchImageryData from "../Search-Imagery/Components/Imagery-Search-Panel/imagery-search-panel";
import SearchImageryResults from '../Search-Imagery/Components/Imagery-Results-Panel/imagery-results-panel';
import SearchImageryLibrarly from "../Search-Imagery/Components/Imagery-Library-Panel/imagery-library-panel"
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import Polygon from "ol/geom/Polygon";
import { GeoJSON } from 'ol/format';
import { intersects, boundingExtent, getIntersection, Extent, getCenter } from 'ol/extent';
import { XYZ } from 'ol/source';
import { defaults } from 'ol/control';
import { fromLonLat } from 'ol/proj';
import "./map.css"
import 'ol/ol.css';
import { getArea } from 'ol/sphere.js';
import { SimpleGeometry } from 'ol/geom';
import Interaction from 'ol/interaction/Interaction';
import Overlay from 'ol/Overlay';
import { setResults } from '../../store/slices/gallerySlice';
import { config } from '../../config/config';
import { useDispatch } from 'react-redux';

const MapDiv = styled.div`
  height: 100%;
  width: 100%;
`;

let drawnSquareFeature: any,
  drawnPolygonFeature: any,
  // uploadVectorFeature: any,
  squareAreaLayer: any,
  polyAreaLayer: any,
  labelOverlay: any,
  labelTotalAreaSizeOverlay: any,
  mapLayer: any,
  // uploadVectorLayer: any,
  // uploadVectorSource: any,
  miniTerrainLayer: any,
  miniMapLayer: any,
  satelliteLayer: any = null;

const MapComponent = () => {
  const dispatch = useDispatch();
  const mapDivRef = useRef<HTMLDivElement | null>(null);
  const mapPreviewDivRef = useRef<HTMLDivElement | null>(null);

  const [imagerySearchPanelPosition] = useState({ x: 0, y: 0 });
  const [imageryResultsPanelPosition] = useState({ x: 0, y: 0 });
  const [imageryLibraryPanelPosition] = useState({ x: 0, y: 0 });

  const [isDefaultMapSelected, setIsDefaultMapSelected] = useState(true);
  // const [searchQuery, setSearchQuery] = useState("");
  const [searchResultsModel, setSearchResultsModel] = useState({});
  const [selectedCords, setSelectedCords] = useState<any[]>([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [lightboxDisplay, setLightBoxDisplay] = useState<boolean>(false);
  const [imageToShow, setImageToShow] = useState("");
  const [selectedInteraction, setSelectedInteraction] = useState<Interaction | null>(null);
  const [totalAreaSelected, setTotalAreaSelected] = useState<number | null>(null);
  const shapeSelectedStyle = new Style({
    stroke: new Stroke({
      color: 'white',
      width: 2
    }),
    fill: new Fill({
      color: 'rgba(77, 82, 78, 0.3)'
    })
  });
  const overlayHoverVectorSource = new VectorSource();
  const overlayHoverVectorLayer = new VectorLayer({
    source: overlayHoverVectorSource,
    style: shapeSelectedStyle
  });
  const searchVectorSource = new VectorSource();
  const searchVectorLayer = new VectorLayer({
    source: searchVectorSource,
    style: new Style({
      stroke: new Stroke({
        color: 'green',
        width: 2
      })
    })
  });
  const uploadVectorSource = new VectorSource();
  const uploadVectorLayer = new VectorLayer({
    source: uploadVectorSource,
    style: shapeSelectedStyle
  });

  const [map, setMap] = useState<Map>(new Map());
  const [mapPreview, setMapPreview] = useState<Map>(new Map());

  useEffect(() => {
    const source = uploadVectorLayer.getSource();
    if (selectedFile) {
      source?.clear(true);
      // Add/Remove layer to update view
      // map.removeLayer(uploadVectorLayer);
      // map.addLayer(uploadVectorLayer);
      handleFileUpload(selectedFile);
    }
  }, [selectedFile]);

  useEffect(() => {
    mapLayer = new TileLayer({
      source: new OSM(),
      visible: true
    });

    satelliteLayer = new TileLayer({
      source: new XYZ({
        url: `https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.pngraw?access_token=${config.REACT_APP_MAPBOX_TOKEN}`,
        // 'http://tile.stamen.com/terrain/{z}/{x}/{y}.jpg',
        // 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        // `https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.pngraw?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
        attributions: [
          '© <a href="https://www.mapbox.com/">Mapbox</a>'
        ]
      }),
      visible: false
    })

    setMap(new Map({
      target: mapDivRef.current || undefined,
      layers: [
        // streetLayer,
        mapLayer,
        satelliteLayer,
        overlayHoverVectorLayer,
        uploadVectorLayer
      ],
      view: new View({
        projection: 'EPSG:4326',
        center: [0, 0],
        zoom: 2,
      }),
    }));

    setMapPreview(new Map({
      target: mapPreviewDivRef.current || undefined,
      controls: defaults({
        zoom: false
      }),
      layers: [
        miniTerrainLayer = new TileLayer({
          source: new XYZ({
            url:
              `https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.pngraw?access_token=${config.REACT_APP_MAPBOX_TOKEN}`
          }),
          // center: [0, 0],
          // zoom: 0,
          visible: true
        }),
        miniMapLayer = new TileLayer({
          source: new OSM(),
          visible: false
        })
      ],
      view: new View({
        projection: 'EPSG:4326',
        center: [0, 0],
        zoom: 0,
        // duration: 200,
        smoothResolutionConstraint: true
      })
    }));

    // Disable dragging and zooming with mouse events
    const interactions = mapPreview.getInteractions(); // Remove all default interactions
    interactions.forEach((interaction: Interaction) => mapPreview.removeInteraction(interaction));
    map.addLayer(searchVectorLayer);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleViewChange = async () => {
    const mainView = map.getView();
    const minimizedView = mapPreview.getView();
    minimizedView.setCenter(mainView.getCenter());
    minimizedView.setZoom(mainView.getZoom() || 1 / 1.5);
    minimizedView.setRotation(mainView.getRotation());
  };


  const mainView = map.getView();
  mainView.on('change:center', handleViewChange);
  mainView.on('change:resolution', handleViewChange);
  mainView.on('change:rotation', handleViewChange);

  const showSearchResults = (result: any) => {
    // Process the geocoding results
    var extent = fromLonLat([result.lon, result.lat], 'EPSG:4326');
    const pointGeometry = new Point(extent);
    const pointFeature = new Feature({
      geometry: pointGeometry
    });
    const source = searchVectorLayer.getSource();
    source?.clear();

    // Add the feature to the vector layer
    source?.addFeature(pointFeature);

    // // Listen for the 'addfeature' event on the vector source
    source?.on('addfeature', function (event) {
      const extent = searchVectorLayer.getSource()?.getExtent();
      if (extent) {
        // Zoom to the extent of the point
        map.getView().fit(extent, {
          padding: [200, 200, 200, 200],
          duration: 2000,
          maxZoom: 15
        });
      }
    });
  };

  const selectUsing = (selectType: selectTypes) => {
    switch (selectType) {
      case selectTypes.square:
        addSquareInteraction();
        break;
      case selectTypes.poly:
        addPolyInteraction();
        break;
      default:
        break;
    }
  }

  const addSquareInteraction = () => {
    squareAreaLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        stroke: new Stroke({
          color: 'green',
          width: 2
        })
      })
    });
    map.addLayer(squareAreaLayer);

    const squareInteraction = new Draw({
      source: squareAreaLayer.getSource(),
      type: "Circle",
      geometryFunction: createBox(),
    })

    // Attach the event listener to the 'drawstart' event
    squareInteraction.on('drawstart', (event) => {
      // Remove the previous label overlay, if it exists
      if (labelOverlay) {
        map.removeOverlay(labelOverlay);
      }

      // Create a new overlay with the label
      labelOverlay = new Overlay({
        element: document.createElement('div'),
        positioning: 'bottom-center',
        offset: [0, -10], // Offset the label above the drawn shape
        stopEvent: false,
      });

      // Add the overlay to the map
      map.addOverlay(labelOverlay);

      // Update the label during the drawing process
      map.on('pointermove', (e) => {
        const coords = e.coordinate;
        // Update the label text
        labelOverlay.getElement().innerHTML = formatAreaString(event.feature.getGeometry());
        // Update the label position to the current pointer position
        labelOverlay.setPosition(coords);
      });
    });

    // Event listener for when the user finishes drawing the selection
    squareInteraction.on('drawend', function (event) {
      dispatch(setResults({ results: [], showMore: false }));

      if (labelOverlay) {
        map.removeOverlay(labelOverlay);
      }
      if (labelTotalAreaSizeOverlay) {
        map.removeOverlay(labelTotalAreaSizeOverlay);
      }
      const selectedArea = event.feature.getGeometry();
      if (selectedArea instanceof SimpleGeometry) {
        // Do something with the selected area, e.g., retrieve its coordinates
        const coordinates = selectedArea.getCoordinates();
        if (coordinates) {
          setSelectedCords(coordinates);
        }
      }

      // Remove the drawn feature from the vector layer
      if (drawnPolygonFeature && polyAreaLayer) {
        polyAreaLayer.getSource().removeFeature(drawnPolygonFeature);
      }
      if (drawnSquareFeature && squareAreaLayer) {
        squareAreaLayer.getSource().removeFeature(drawnSquareFeature);
      }

      drawnSquareFeature = event.feature;
      const extent: Extent = drawnSquareFeature.getGeometry().getExtent();
      // Zoom to the extent of the point
      map.getView().fit(extent, {
        padding: [50, 50, 50, 50],
        duration: 1000
      });

      // Get the geometry of the drawn feature
      const geometry = drawnSquareFeature.getGeometry();
      // Calculate the center of the drawn shape based on the geometry type
      let center;
      if (geometry instanceof SimpleGeometry) {
        if (geometry.getType() === 'Point') {
          center = geometry.getCoordinates();
        } else if (geometry.getType() === 'LineString') {
          const coords = geometry.getCoordinates();
          if (coords) {
            center = [
              (coords[0][0] + coords[1][0]) / 2,
              (coords[0][1] + coords[1][1]) / 2,
            ];
          }
        } else if (geometry.getType() === 'Polygon') {
          center = getCenter(geometry.getExtent());
        } else if (geometry.getType() === 'Circle') {
          center = (geometry as any).getCenter();
        }
      }

      if (geometry) {
        // Calculate the area in square meters
        // const areaInMeters = getArea(geometry, {
        //   projection: 'EPSG:4326',
        // });
      }

      // Create a new overlay with the label
      labelTotalAreaSizeOverlay = new Overlay({
        position: center,
        positioning: 'center-center',
        element: document.createElement('div'),
      });

      // Create the label text
      const label = document.createElement('div');
      label.className = 'sqm-label';
      setTotalAreaSelected(formatAreaInt(selectedArea));
      label.innerHTML = formatAreaString(selectedArea);//`Area: ${areaInMeters.toFixed(2)} sqm`;

      // Add the label to the overlay element
      labelTotalAreaSizeOverlay.getElement()?.appendChild(label);

      // Add the overlay to the map
      map.addOverlay(labelTotalAreaSizeOverlay);
    });

    if (selectedInteraction) {
      map.removeInteraction(selectedInteraction);
    }
    // Add the interaction to the map
    map.addInteraction(squareInteraction);
    setSelectedInteraction(squareInteraction);
  };

  const formatAreaString = function (polygon: any) {
    const area = getArea(polygon, { projection: 'EPSG:4326' });
    let output;
    if (area > 10000) {
      output = `Area: ${Math.round((area / 1000000) * 100) / 100} km<sup>2</sup>`;
    } else {
      output = `Area: ${Math.round(area * 100) / 100} m<sup>2</sup>`;
    }
    return output;
  };

  const formatAreaInt = function (polygon: any) {
    const area = getArea(polygon, { projection: 'EPSG:4326' });
    let output;
    if (area > 10000) {
      output = Math.round((area / 1000000) * 100) / 100;
    } else {
      output = Math.round(area * 100) / 100;
    }
    return output;
  };

  const addPolyInteraction = () => {
    polyAreaLayer = new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        stroke: new Stroke({
          color: 'green',
          width: 2
        })
      })
    });
    map.addLayer(polyAreaLayer);

    // Create an interaction for drawing the selection
    const polyInteraction = new Draw({
      type: 'Polygon', // Specify the type of shape to draw (Polygon in this case)
      source: polyAreaLayer.getSource(),
    });

    // Attach the event listener to the 'drawstart' event
    polyInteraction.on('drawstart', (event) => {
      // Remove the previous label overlay, if it exists
      if (labelOverlay) {
        map.removeOverlay(labelOverlay);
      }

      // Create a new overlay with the label
      labelOverlay = new Overlay({
        element: document.createElement('div'),
        positioning: 'bottom-center',
        offset: [0, -10], // Offset the label above the drawn shape
        stopEvent: false,
      });

      // Add the overlay to the map
      map.addOverlay(labelOverlay);

      // Update the label during the drawing process
      map.on('pointermove', (e) => {
        const coords = e.coordinate;
        // Update the label text
        labelOverlay.getElement().innerHTML = formatAreaString(event.feature.getGeometry());
        // Update the label position to the current pointer position
        labelOverlay.setPosition(coords);
      });
    });

    // Event listener for when the user finishes drawing the selection
    polyInteraction.on('drawend', function (event) {
      dispatch(setResults({ results: [], showMore: false }));

      if (labelOverlay) {
        map.removeOverlay(labelOverlay);
      }
      if (labelTotalAreaSizeOverlay) {
        map.removeOverlay(labelTotalAreaSizeOverlay);
      }

      const selectedArea = event.feature.getGeometry();

      if (selectedArea instanceof SimpleGeometry) {
        const coordinates = selectedArea.getCoordinates();
        if (coordinates) {
          // Do something with the selected area, e.g., retrieve its coordinates
          setSelectedCords(coordinates);
        }
      }

      // Remove the drawn feature from the vector layer
      if (drawnPolygonFeature) {
        polyAreaLayer.getSource().removeFeature(drawnPolygonFeature);
      }
      if (drawnSquareFeature) {
        squareAreaLayer.getSource().removeFeature(drawnSquareFeature);
      }

      drawnPolygonFeature = event.feature;
      const extent = drawnPolygonFeature.getGeometry().getExtent();
      // Zoom to the extent of the point
      map.getView().fit(extent, {
        padding: [50, 50, 50, 50],
        duration: 1000
      });

      // Get the geometry of the drawn feature
      const geometry = drawnPolygonFeature.getGeometry();
      // Calculate the center of the drawn shape based on the geometry type
      let center;
      if (geometry instanceof SimpleGeometry) {
        if (geometry.getType() === 'Point') {
          center = geometry.getCoordinates();
        } else if (geometry.getType() === 'LineString') {
          const coords = geometry.getCoordinates();
          if (coords) {
            center = [
              (coords[0][0] + coords[1][0]) / 2,
              (coords[0][1] + coords[1][1]) / 2,
            ];
          }
        } else if (geometry.getType() === 'Polygon') {
          center = getCenter(geometry.getExtent());
        } else if (geometry.getType() === 'Circle') {
          center = (geometry as any).getCenter();
        }
      }

      // Create a new overlay with the label
      labelTotalAreaSizeOverlay = new Overlay({
        position: center,
        positioning: 'center-center',
        element: document.createElement('div'),
      });

      // Create the label text
      const label = document.createElement('div');
      label.className = 'sqm-label';
      setTotalAreaSelected(formatAreaInt(selectedArea));
      label.innerHTML = formatAreaString(selectedArea);//`Area: ${areaInMeters.toFixed(2)} sqm`;

      // Add the label to the overlay element
      labelTotalAreaSizeOverlay.getElement()?.appendChild(label);

      // Add the overlay to the map
      map.addOverlay(labelTotalAreaSizeOverlay);

      // Remove the drawn feature from the vector layer

    });

    if (selectedInteraction) {
      map.removeInteraction(selectedInteraction);
    }
    // Add the interaction to the map
    map.addInteraction(polyInteraction);
    setSelectedInteraction(polyInteraction);
  }

  const handleResize = () => {
    map.updateSize();
  };

  const onHoverDone = () => {
    const source = overlayHoverVectorLayer.getSource();
    source?.clear();
  };

  // const onResultHover = (result: any) => {
  //   // Create an extent from the selected area's coordinates
  //   const selectedAreaExtent = boundingExtent(selectedCords[0]);
  //   const footprintExtent = boundingExtent(result.geometry.coordinates[0]);

  //   if (intersects(selectedAreaExtent, footprintExtent)) {
  //     // Use the footprintExtent as the extent for the polygon
  //     const polygonCoordinates = [
  //       [
  //         [footprintExtent[0], footprintExtent[1]],
  //         [footprintExtent[0], footprintExtent[3]],
  //         [footprintExtent[2], footprintExtent[3]],
  //         [footprintExtent[2], footprintExtent[1]],
  //         [footprintExtent[0], footprintExtent[1]]
  //       ]
  //     ];
  //     const polygonGeometry = new Polygon(polygonCoordinates);
  //     const polygonFeature = new Feature({
  //       geometry: polygonGeometry
  //     });
  //     const source = overlayHoverVectorLayer.getSource();
  //     source?.clear();

  //     // Add the feature to the vector layer
  //     source?.addFeature(polygonFeature);

  //     // Add/Remove layer to update view
  //     map.removeLayer(overlayHoverVectorLayer);
  //     map.addLayer(overlayHoverVectorLayer);

  //     // Listen for the 'addfeature' event on the vector source
  //     source?.on('addfeature', function (event) {
  //     });
  //   }
  // };

  // const onResultHover = (result: any) => {
  //   // Create a temporary vector layer to hold the intersection feature
  //   // var tempLayer = new VectorLayer({
  //   //   source: new Vector()
  //   // });

  //   // // Calculate the intersection geometry
  //   // var intersectionGeometry = null;
  //   // var intersection;
  //   // const markGeometry = new Polygon(selectedCords[0]);
  //   // const footprintGeometry = new Polygon(result.geometry.coordinates[0]);
  //   // try {
  //   //   intersectionGeometry = markGeometry.clone().intersection(footprintGeometry);
  //   // } catch (error) {
  //   //   console.error('Error during intersection calculation:', error);
  //   // }

  //   // // Clear previous intersection features
  //   // sourceFootprint.clear();

  //   // if (intersectionGeometry) {
  //   //   // Create a new feature with the intersection geometry
  //   //   var intersectionFeature = new Feature({
  //   //     geometry: intersectionGeometry
  //   //   });

  //   //   // Add the intersection feature to the footprint layer
  //   //   sourceFootprint.addFeature(intersectionFeature);
  //   // }

  //   // debugger
  //   // Create an interaction to draw the intersection extent
  //   // const draw = new Draw({
  //   //   source: new Vector(),
  //   //   type: 'Polygon',
  //   // });

  //   // draw.on('drawend', (event) => {
  //   //   const intersectionFeature = event.feature;
  //   //   const intersectionExtent = intersectionFeature.getGeometry()?.getExtent();
  //   //   const intersectionLayer = new VectorLayer({
  //   //     source: new Vector({
  //   //       features: [intersectionFeature],
  //   //     }),
  //   //     style: new Style({
  //   //       fill: new Fill({
  //   //         color: 'rgba(255, 0, 0, 0.4)', // Red with 40% opacity
  //   //       }),
  //   //     }),
  //   //   });

  //   //   map.addLayer(intersectionLayer);
  //   // });
  //   // Create the intersection extent
  //   // const markExtent = new Polygon(result.geometry.coordinates[0]).getExtent();//map.getView().calculateExtent(map.getSize());
  //   // const footprintExtent = new Polygon(result.geometry.coordinates[0]).getExtent();
  //   // // const selectedAreaExtent = boundingExtent(selectedCords[0]);
  //   // // const footprintExtent = boundingExtent(result.geometry.coordinates[0]);
  //   // // const intersectionExtent = getIntersection(selectedAreaExtent, footprintExtent);

  //   // // // Create a vector layer for the intersection extent
  //   // // const intersectionFeature = new Feature({
  //   // //   geometry: fromExtent(intersectionExtent),
  //   // // });

  //   // // const intersectionSource = new Vector({
  //   // //   features: [intersectionFeature],
  //   // // });

  //   // // const intersectionLayer = new VectorLayer({
  //   // //   source: intersectionSource,
  //   // //   style: new Style({
  //   // //     fill: new Fill({
  //   // //       color: 'rgba(255, 0, 0, 0.4)', // Red with 40% opacity
  //   // //     }),
  //   // //   }),
  //   // // });

  //   // // map.addLayer(intersectionLayer);
  //   // Create the intersection extent

  //   // // const markGeometry = new Polygon(selectedCords[0]);
  //   // // const markFeature = new Feature({
  //   // //   geometry: markGeometry
  //   // // });
  //   // // const footprintGeometry = new Polygon(result.geometry.coordinates[0]);
  //   // // const footprintFeature = new Feature({
  //   // //   geometry: footprintGeometry
  //   // // });
  //   // // const mapExtent = markFeature.getGeometry()?.getExtent();//boundingExtent(selectedCords[0]);//map.getView().calculateExtent(map.getSize());
  //   // // const triangleExtent = footprintFeature.getGeometry()?.getExtent();//boundingExtent(result.geometry.coordinates[0]);//triangleFeature.getGeometry().getExtent();
  //   // // if (mapExtent && triangleExtent && markGeometry instanceof SimpleGeometry) {
  //   // //   var intersectionGeometry = markGeometry.intersectsExtent(footprintGeometry.getExtent())
  //   // //     ? markGeometry.clone().intersection(footprintGeometry)
  //   // //     : null;
  //   // //   // const intersectionExtent = getIntersection(mapExtent, triangleExtent);

  //   // //   // Create a vector layer for the intersection extent
  //   // //   if (intersectionGeometry) {
  //   // //     const intersectionFeature = new Feature({
  //   // //       geometry: fromExtent(intersectionExtent),
  //   // //     });

  //   // //     const intersectionSource = new Vector({
  //   // //       features: [intersectionFeature],
  //   // //     });

  //   // //     const intersectionLayer = new VectorLayer({
  //   // //       source: intersectionSource,
  //   // //       style: new Style({
  //   // //         fill: new Fill({
  //   // //           color: 'rgba(255, 0, 0, 0.4)', // Red with 40% opacity
  //   // //         }),
  //   // //       }),
  //   // //     });

  //   // //     map.addLayer(intersectionLayer);
  //   // //   }

  //   // // }

  //   // // const selectedAreaExtent = boundingExtent(selectedCords[0]);
  //   // // const footprintExtent = boundingExtent(result.geometry.coordinates[0]);
  //   // // const intersectionExtent = getIntersection(selectedAreaExtent, footprintExtent);


  // };

  const onResultHover = (result: any) => {
    // Create an extent from the selected area's coordinates
    const selectedAreaExtent = boundingExtent(selectedCords[0]);
    // const footprintExtent = boundingExtent(result.geometry.coordinates[0])
    const footprintExtent = result.geometry.coordinates[0].length > 1 ? boundingExtent(result.geometry.coordinates[0]) : boundingExtent(result.geometry.coordinates[0][0]);
    if (intersects(selectedAreaExtent, footprintExtent)) {
      const intersectionExtent = getIntersection(selectedAreaExtent, footprintExtent);
      if (intersectionExtent) {
        // Create the polygon coordinates from the extent
        const polygonCoordinates = [
          [[intersectionExtent[0], intersectionExtent[1]], [intersectionExtent[0], intersectionExtent[3]], [intersectionExtent[2], intersectionExtent[3]], [intersectionExtent[2], intersectionExtent[1]], [intersectionExtent[0], intersectionExtent[1]]]
        ];
        const polygonGeometry = new Polygon(polygonCoordinates);//([polygonCoordinates.map(coord => fromLonLat(coord, 'EPSG:4326'))]);
        const polygonFeature = new Feature({
          geometry: polygonGeometry
        });

        const source = overlayHoverVectorLayer.getSource();
        source?.clear();

        // Add the feature to the vector layer
        source?.addFeature(polygonFeature);

        // Add/Remove layer to update view
        map.removeLayer(overlayHoverVectorLayer);
        map.addLayer(overlayHoverVectorLayer);

        // Listen for the 'addfeature' event on the vector source
        source?.on('addfeature', function (event) {
        });
      };
    };
  }

  const onLibraryHover = (result: any) => {

    const footprintExtent = result.aoi.coordinates.length > 1 ? boundingExtent(result.aoi.coordinates) : boundingExtent(result.aoi.coordinates[0]);
    const polygonCoordinates = [
      [[footprintExtent[0], footprintExtent[1]], [footprintExtent[0], footprintExtent[3]], [footprintExtent[2], footprintExtent[3]], [footprintExtent[2], footprintExtent[1]], [footprintExtent[0], footprintExtent[1]]]
    ];
    const polygonGeometry = new Polygon(polygonCoordinates);
    const polygonFeature = new Feature({
      geometry: polygonGeometry
    });
    const source = overlayHoverVectorLayer.getSource();
    source?.clear();

    // Add the feature to the vector layer
    source?.addFeature(polygonFeature);

    // Add/Remove layer to update view
    map.removeLayer(overlayHoverVectorLayer);
    map.addLayer(overlayHoverVectorLayer);

    // Listen for the 'addfeature' event on the vector source
    source?.on('addfeature', function (event) {
      drawnPolygonFeature = event.feature;
      const extent = drawnPolygonFeature.getGeometry().getExtent();
      // Zoom to the extent of the point
      map.getView().fit(extent, {
        padding: [200, 200, 200, 200],
        duration: 2000,
        maxZoom: 20
      });
    });
  };

  const handleFileUpload = (file: any) => {
    const reader = new FileReader();

    const source = uploadVectorLayer.getSource();
    // source?.clear();

    reader.onload = (e: any) => {
      dispatch(setResults({ results: [], showMore: false }));
      const geojson = JSON.parse(e.target.result);
      const format = new GeoJSON();

      const uploadVectorFeature = format.readFeature(geojson);

      // Add the feature to the vector layer
      // source.addFeature(polygonFeature);
      source?.addFeature(uploadVectorFeature);
      // Add/Remove layer to update view
      map.removeLayer(uploadVectorLayer);
      map.addLayer(uploadVectorLayer);

      // Listen for the 'addfeature' event on the vector source
      // source?.on('addfeature', (event: any) => {
      //   debugger
      // });

      // map.removeLayer(uploadVectorLayer);
      // map.addLayer(uploadVectorLayer);

      const selectedArea = uploadVectorFeature.getGeometry();

      if (selectedArea instanceof SimpleGeometry) {
        const coordinates = selectedArea.getCoordinates();
        if (coordinates) {
          // Do something with the selected area, e.g., retrieve its coordinates
          setSelectedCords(coordinates);
        }
      }

      setTotalAreaSelected(formatAreaInt(selectedArea));
      map.getView().fit(uploadVectorSource.getExtent(), { maxZoom: 14 });
    };

    reader.readAsText(file);
  };

  const toggleLayers = () => {
    // Toggle visibility of the layers
    setIsDefaultMapSelected(!isDefaultMapSelected);

    miniTerrainLayer.setVisible(!miniTerrainLayer.getVisible());
    miniMapLayer.setVisible(!miniMapLayer.getVisible());

    mapLayer.setVisible(!mapLayer.getVisible());
    satelliteLayer.setVisible(!satelliteLayer.getVisible());
  };

  return (
    <MapDiv ref={mapDivRef}>
      <mapContext.Provider value={{
        setSelectionMode: selectUsing,
        setSelectedFile,
        cords: {
          selectedCords,
          setSelectedCords,
          totalAreaSelected
        },
        showSearchResults,
        searchResults: {
          searchResultsModel,
          setSearchResultsModel
        },
        lightbox: {
          toggle: setLightBoxDisplay,
          setSrc: setImageToShow

        },
        onResultHover,
        onLibraryHover,
        onHoverDone
      }}>
        {lightboxDisplay ?
          <div id="lightbox" onClick={() => { setLightBoxDisplay(false) }}>
            <img id="lightbox-img" src={imageToShow}></img>
          </div>
          : ''}
        {/* <div className={!isDefaultMapSelected && `toggle-map default-map-btn` || `toggle-map satellite-map-btn`} onClick={toggleLayers}></div> */}
        <div className="map-preview" id="map-preview" ref={mapPreviewDivRef} onClick={toggleLayers}></div>
        <SearchImageryData position={imagerySearchPanelPosition}></SearchImageryData>
        {/* handler={bindImagerySearchPanel} */}
        <SearchImageryResults position={imageryResultsPanelPosition}></SearchImageryResults>
        <SearchImageryLibrarly position={imageryLibraryPanelPosition}></SearchImageryLibrarly>
        {/* handler={bindImageryResultsPanel} */}
      </mapContext.Provider>
    </MapDiv>
  );
};

export default MapComponent;