import { useState } from "react";
import { Token } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../store/slices/gallerySlice";
import {
  selectCartItems,
  updateCartItemStatus, removeFromCart, selectCartTotalPrice,
} from "../redux/slices/cartSlice";
import CreditSystem from "./credit_system";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckoutSummary from "./checkout_summary";
import {
  CartItem
} from "../../Shared/Models/search-imagery-result.model";
import oneAtlasService from "../../../services/one-atlas.service";
import "./cart.css";
import { OrderDataProduct } from "../products.interface";
export interface CheckedItems {
  [key: number]: boolean;
}

const Cart = () => {
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});
  const cartItems: CartItem[] = useSelector(selectCartItems);
  const totalPrice: number = useSelector(selectCartTotalPrice);

  const token = useSelector(getToken);
  const [isCheckedOut, setIsCheckOut] = useState<boolean>(false);
  const dispatch = useDispatch();

  const getSelectedIds = () => {
    return Object.keys(checkedItems)
      .filter((key: any) => checkedItems[key])
      .map((key) => parseInt(key));
  };

  const onRemoveItem = (itemId: number) => {
    dispatch(removeFromCart(itemId));
  };

  const onCheckout = async () => {
    setIsCheckOut(true);
    dispatch({
      type: "UPDATE_DATA",
      payload: { totalPrice: totalPrice },
    });
    // await DataPersistancyService.getInstance().savePrice(token, totalPrice);
    const checkoutItems: CartItem[] = cartItems.filter(item => checkedItems[item.id]);
    const orederResults = await oneAtlasService.cartCheckout(checkoutItems, token);
    orederResults.forEach(async (result) => {
      const response: OrderDataProduct = await (result as any).value?.json();

      if (result.status === "fulfilled") {
        const { products } = response;
        dispatch(
          updateCartItemStatus({
            id: products[0].id,
            status: "Completed",
          })
        );
      } else {
        const rejectReason = await result.reason.error.json();
        dispatch(updateCartItemStatus({ id: result.reason.id, status: "Failed", error: rejectReason.message }));
      }
    });
  };

  const handleCheckboxChange = (itemId: number) => {
    console.log(itemId)
    setCheckedItems((prev) => {
      const isChecked = !!prev[itemId];

      if (isChecked) {
        const { [itemId]: _, ...newCheckedItems } = prev;
        return newCheckedItems;
      } else {
        return { ...prev, [itemId]: true };
      }
    });
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ flex: "1" }}>
        {!isCheckedOut ? (
          <div className="cart-container">
            <div className="cart">
              <div className="cart-header">
                <div className="header-item">Name</div>
                <div className="header-item">Currency</div>
                <div className="header-item">Price</div>
              </div>
              {cartItems.map((item: any) => (
                <div key={item.id} className={`cart-item ${item.status === 'Completed' ? 'disabled' : ''}`}>
                  <input
                    type="checkbox"
                    checked={!!checkedItems[item.id]}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                  <img
                    src={`${item.thumbnailUrl}`}
                    alt="thumbnail"
                    className="item-thumbnail"
                  />
                  <div className="item-details">
                    <div className="item-name">
                      {item.item.id.length > 6
                        ? `${item.item.id.substring(0, 11)}...`
                        : item.item.id}
                    </div>
                    <div className="item-size">{item.currency}</div>
                    <div className="item-price">
                      <Token className="token" style={{ color: "navy" }} />
                      {item.price}
                    </div>
                  </div>
                  <button onClick={() => onRemoveItem(item.id)}><DeleteIcon /></button>
                </div>
              ))}
              <div className="cart-footer">
                <div className="cart-total">
                  Total: <Token className="token" style={{ color: "navy" }} />
                  {totalPrice}
                </div>
                <div className="cart-actions">
                  <button
                    onClick={onCheckout}
                    disabled={!Object.keys(checkedItems).length}
                    className={
                      !Object.keys(checkedItems).length ? "button-disabled" : ""
                    }
                  >
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <CheckoutSummary selectedIds={getSelectedIds()} />
        )}
      </div>
      {!isCheckedOut && (
        <div className="new-element" style={{ flex: "0.3" }}>
          <CreditSystem />
        </div>
      )}
    </div>
  );
};

export default Cart;
