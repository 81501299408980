import './App.css';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Layout from './components/Layout/layout';
import Popup from './components/Modals/PopupContext';
import { useAuth0 } from "@auth0/auth0-react";
import SocketIoService from './services/socketIo.service';
import { setToken as setStoreToken } from "./store/slices/gallerySlice";


function App() {
  const { isAuthenticated, isLoading, error, getAccessTokenSilently }: any = useAuth0();

  const [token, setToken]: any = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    (async function getToken() {
      try {
        const tok = await getAccessTokenSilently();
        setToken(tok);
      } catch (error) {
        setToken(null);
      }
    })()
  }, [isAuthenticated]);

  useEffect(() => {
    if (token) {
      SocketIoService.connectToSocketIo(token, dispatch);
      dispatch(setStoreToken(token));
    }
  }, [token])

  return (
    <>
      {error && <p>Auth Error</p>}
      {!error && isLoading && <p>Loading...</p>}
      {!error && !isLoading && (
        <Popup>
          <Layout></Layout>
        </Popup>
      )}
    </>
  );
}

export default App;