
import React from "react";
import "./top-menu.css";

const TopMenu = ({ position, format, items, isVisibale }: any) => {

    return (
        <div className="menu-container" style={{ display: isVisibale ? 'block' : 'none', left: items.filter((i: any) => i.isVisibale).length > 1 ? '89%' : '92%' }}>
            <ul>
                {
                    items?.map((item: any) => {
                        return (item.isVisibale &&
                            <li key={item.title}>
                                <a onClick={item.customFunction}>
                                    <item.icon></item.icon>&nbsp;
                                    <span>{item.title}</span>
                                </a>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default TopMenu