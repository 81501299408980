import React, { createContext, useState } from 'react';
import PopupComponent from './Popup';

export const PopupContext = createContext({});

const Popup = ({ children }: any) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState('');

  const openPopup = (content: any) => {
    setPopupContent(content);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <PopupContext.Provider value={{ openPopup, closePopup }}>
      {children}
      <PopupComponent isOpen={isPopupOpen} onClose={closePopup} content={popupContent} />
    </PopupContext.Provider>
  );
};

export default Popup;
