import socketIOClient from "socket.io-client"
import TosterSrv from "./toster.service";
import { getGallery, updateGallery } from "../store/slices/gallerySlice";
import { SocketIoEventName } from "../components/Shared/Models/search-imagery-result.model"
import { config } from '../config/config'
import { useDispatch } from "react-redux";
import oneAtlasService from "./one-atlas.service";

class SocketIOService {
    private discoverEP: string | undefined = config.REACT_APP_SOCKETIO_ENDPOINT;
    private static instance: SocketIOService | null;

    static getInstance(): SocketIOService {
        if (!this.instance) {
            this.instance = new SocketIOService();
        }
        return this.instance;
    }
    socket: any;

    connectToSocketIo = (tok: string, dispatch: any) => {
        this.socket = socketIOClient(this.discoverEP || "", {
            extraHeaders: {
                "authorization": `Bearer ${tok}`,
                "Content-Type": "application/json",
            }
        });
        this.subscribeToEvents(tok, dispatch);
    }

    subscribeToEvents = (token: string, dispatch: any) => {
        this.socket.on(SocketIoEventName.orderDelivered, (data: any) => {
            oneAtlasService.getGallery(token).then(res => dispatch(updateGallery(res)));
            TosterSrv.InfoLink("Order completed:", data.products[0].thumbnailUrl);
        });

        this.socket.on(SocketIoEventName.info, (data: any) => {
            TosterSrv.Info(data);
        });
    };
}

export default SocketIOService.getInstance();