import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import mapContext from "../Map/mapContext";
// import { SearchMenuItem } from "../Shared/Components/Search-Menu-Item/search-menu-item";
import OneAtlasService from '../../services/one-atlas.service';
import { MenuItem, CircularProgress } from '@mui/material';
import "./search-bar.css";

const SearchBar = () => {
  const [query, setQuery] = useState("");
  const { showSearchResults }: any = useContext(mapContext);
  const [loading, setLoading] = useState(false);
  const [toggleResults, setToggleResults] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    document.getElementById("inputBox")?.addEventListener(
      "keydown", keypressInBox);

    // document.getElementById("search-bar").addEventListener(
    //   "focusout", () => { setToggleResults(false) });
  }, [])

  const keypressInBox = (e: any) => {
    var code = (e.keyCode ? e.keyCode : e.which);
    if (code == 13) { //Enter keycode                        
      e.preventDefault();
      search(e.currentTarget.value);
      // e.currentTarget.blur();
    }
  };

  const search = (query: string) => {
    // Perform geocoding using a geocoding service OpenStreetMap Nominatim:
    OneAtlasService.searchAddress(query).then((data) => {
      if (data.length > 0) {
        setToggleResults(true);
        setResults(data);
      } else {
        console.log('No results found.');
      }
    })
  };

  const showOnMap = (result: any) => {
    setToggleResults(false);
    showSearchResults(result);
  };

  const onSearchValueChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuery(event.target.value);
  };

  return (
    <div className="input-icons">
      <div className="inputBox_container">
        <svg className="search_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path d="M46.599 46.599a4.498 4.498 0 0 1-6.363 0l-7.941-7.941C29.028 40.749 25.167 42 21 42 9.402 42 0 32.598 0 21S9.402 0 21 0s21 9.402 21 21c0 4.167-1.251 8.028-3.342 11.295l7.941 7.941a4.498 4.498 0 0 1 0 6.363zM21 6C12.717 6 6 12.714 6 21s6.717 15 15 15c8.286 0 15-6.714 15-15S29.286 6 21 6z">
          </path>
        </svg>
        <input className="inputBox" id="inputBox" type="text" placeholder="Search Place or Coordinate"></input>
      </div>
      {loading && <CircularProgress />} {/* Show a loading indicator while fetching results */}
      {
        toggleResults &&
        <div className="search-results-panel">
          {results?.map((result: any) => (
            <MenuItem key={result.place_id} onClick={() => showOnMap(result)}>
              {result.display_name}
            </MenuItem>
          ))}
        </div>
      }
    </div>
  );
}

export default SearchBar;