import React from "react";
class SearchResult {
    key: string;
    title: string;
    date: Date;
    sensor: string;
    cloudesCover: number;
    ratio: number;
    angle: number;
    thumbnailUrl: string;

    constructor(
        title: string,
        key: string
    ) {
        this.key = key;
        this.title = title;
        this.date = new Date();
        this.sensor = "RGB";
        this.cloudesCover = 45;
        this.ratio = 50;
        this.angle = 90;
        this.thumbnailUrl = "https://images.pexels.com/photos/326055/pexels-photo-326055.jpeg?auto=compress&cs=tinysrgb&w=600"
    }

}

export interface SearchResults {
    results: any[],
    showMore: boolean
}

export enum Supplier {
    OneAtlas,
    SkyFi,
    SentinelHub
}

export enum selectTypes {
    none = "none",
    poly = "poly",
    square = "square",
    circle = "circle",
};

export enum productType {
    bundle = 'bundle',
    pansharpened = 'pansharpened',
    panchromatic = 'panchromatic',
    multiSpectral = 'multiSpectral',
    bundlefs = 'bundle-fs',
    pansharpenedfs = 'pansharpened-fs'
};

export const radiometricProcessing = {
    basic16: 'BASIC16',
    reflectance: 'REFLECTANCE',
    display: 'DISPLAY'
};

export const imageFormat = {
    geotiff: 'image/geotiff',
    jp2: 'image/jp2'
};

export const OrderStatus = {
    rejected: 'rejected',
    ordered: 'ordered',
    delivered: 'delivered'
}

export const SocketIoEventName = {
    info: 'info',
    orderDelivered: "orderDelivered"
}

export const MenuFormat = {
    textOnly: 'textOnly',
    iconsOnly: 'iconsOnly',
    textAndIcons: 'textAndIcons'
}

export interface PriceModel {
    currency: string,
    price: number
}

export class SearchModel {
    itemsPerPage: number;
    startPage: number;
    dateRange: Date[];
    sensors: any[];
    productType: productType[];
    cloudCover: number;
    incidenceAngle: [number, number];
    processingLevel: any;
    resolutionRange: number[];
    geometry: any;
    constructor() {
        this.itemsPerPage = 5;
        this.startPage = 1;
        this.dateRange = [];
        this.sensors = [];
        this.productType = [
            productType.panchromatic,
            productType.bundlefs,
            productType.bundle,
            productType.multiSpectral,
            productType.pansharpenedfs,
            productType.pansharpened]
        this.cloudCover = 0;
        this.incidenceAngle = [0, 40];
        this.processingLevel = {};
        this.resolutionRange = [];
        this.geometry = {
            type: "",
            coordinates: []
        }
    }
}

export interface OrderData {
    id: string,
    coordinates: any,
    productType: productType,
    collection?: string,
    supplier: number,
    cloudCover?: number
}

export interface CartItem {
    id: number,
    item: OrderData,
    thumbnailUrl: string,
    price: number,
    currency?: string,
    status: CartItemStatus
}

export type CartItemStatus = "Completed" | "Pending" | "Failed"

export default SearchResult;