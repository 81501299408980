import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { config } from '../config/config';

const AuthProvider = ({ children }: any) => {
    const domain = config.REACT_APP_AUTH0_DOMAIN;
    const clientId = config.REACT_APP_AUTH0_CLIENT_ID;
    const nav = useNavigate();

    const onRedirectCallback = (appState: any) => {
        nav(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: window.location.origin
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default AuthProvider;