import React, { Suspense } from "react";
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../Dashboard/dashboard';
import Cart from "../Payments/components/cart";
import CreditSystem from "../Payments/components/credit_system";
import OrderConfirmation from "../Payments/components/order_confirmation";
import CheckoutSummary from "../Payments/components/checkout_summary";
const PaymentsContainer = React.lazy(() => import("../Payments/components/container"));

export default function Router({ children }: any) {

  return (
    <div style={{ flex: 1, overflow: 'auto', height: 'inherit' }}>
      <section style={{ height: 'inherit' }}>
        <Suspense>
          <Routes>
            <Route path="/payments" element={<PaymentsContainer />}></Route>
            <Route path="/" element={<Dashboard />}></Route>
            <Route path="/payments/cart" element={<Cart />}></Route>
            {/* <Route path="/payments/credit" element={<CheckoutSummary />}></Route> */}
            <Route path="/payments/confirm" element={<OrderConfirmation />}></Route>

          </Routes>
        </Suspense>
      </section>
    </div>
  );
}