import { CartItemStatus } from '../../Shared/Models/search-imagery-result.model'

export const UPDATE_DATA = "UPDATE_DATA";
export const DEDUCT_PRICE = "DEDUCT_PRICE";
export const INCREMENT_PRICE = "INCREMENT_PRICE";
export const SET_PAYMENT_INTENT = "SET_PAYMENT_INTENT";
export const SET_USER_INFO = "SET_USER_INFO";
export const CHARGE_TOKENS = "CHARGE_TOKENS";

export interface SetPaymentIntentAction {
  type: typeof SET_PAYMENT_INTENT;
  payload: {
    amount: number;
    currency: string;
    customer: string;
    description: string;
    createdTimestamp: number;
  };
}

export interface ChargeTokensAction {
  type: typeof CHARGE_TOKENS,
  payload: boolean
}

interface UpdateDataAction {
  type: typeof UPDATE_DATA;
  payload: any;
}

interface DeductPriceAction {
  type: typeof DEDUCT_PRICE;
  payload: number;
}

interface IncrementPriceAction {
  type: typeof INCREMENT_PRICE;
  payload: number;
}



export type BalanceActionTypes =
  | UpdateDataAction
  | DeductPriceAction
  | IncrementPriceAction;

export const incrementPrice = (amount: number): IncrementPriceAction => {
  return {
    type: INCREMENT_PRICE,
    payload: amount,
  };
};

export const updateData = (newData: any): UpdateDataAction => {
  return {
    type: UPDATE_DATA,
    payload: newData,
  };
};

export const deductPrice = (amount: number): DeductPriceAction => {
  return {
    type: DEDUCT_PRICE,
    payload: amount,
  };
};

export const setPaymentIntent = (paymentIntentData: any) => ({
  type: SET_PAYMENT_INTENT,
  payload: paymentIntentData,
});

export const setUserInfo = (userInfo: any) => ({
  type: SET_USER_INFO,
  payload: userInfo
});

export const chargeTokens = (isCharged: boolean) => ({
  type: CHARGE_TOKENS,
  payload: isCharged
});